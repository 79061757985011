@charset "UTF-8";
@font-face {
  font-family: 'fontello';
  src: url("https://cdn.bztech.com.br/font/fontello.eot");
  src: url("../font/fontello.eot?#iefix") format("embedded-opentype"), url("../font/fontello-bztech.woff") format("woff"), url("../font/fontello.ttf") format("truetype"), url("../font/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="fontello-icon-"]:before,
[class*=" fontello-icon-"]:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: 0.7; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  /* fix buttons height */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /*  boo add font size */
  font-size: 1.166em;
}

.fontello-icon-plus:before {
  content: '\2b';
}

/* '+' */
.fontello-icon-plus-1:before {
  content: '\e80f';
}

/* '' */
.fontello-icon-plus-2:before {
  content: '\e87d';
}

/* '' */
.fontello-icon-plus-4:before {
  content: '\e913';
}

/* '' */
.fontello-icon-minus:before {
  content: '\2d';
}

/* '-' */
.fontello-icon-minus-1:before {
  content: '\e811';
}

/* '' */
.fontello-icon-home:before {
  content: '\2302';
}

/* '⌂' */
.fontello-icon-right-dir-3:before {
  content: '\e9da';
}

/* '' */
.fontello-icon-left-dir:before {
  content: '\25c2';
}

/* '◂' */
.fontello-icon-star-1:before {
  content: '\e804';
}

/* '' */
.fontello-icon-star-empty-1:before {
  content: '\e805';
}

/* '' */
.fontello-icon-check:before {
  content: '\2611';
}

/* '☑' */
.fontello-icon-attention:before {
  content: '\26a0';
}

/* '⚠' */
.fontello-icon-cog-alt:before {
  content: '\26ef';
}

/* '⛯' */
.fontello-icon-pencil:before {
  content: '\270e';
}

/* '✎' */
.fontello-icon-ok:before {
  content: '\2713';
}

/* '✓' */
.fontello-icon-ok-2:before {
  content: '\e854';
}

/* '' */
.fontello-icon-ok-circle:before {
  content: '\2714';
}

/* '✔' */
.fontello-icon-ok-circle-1:before {
  content: '\e855';
}

/* '' */
.fontello-icon-cancel:before {
  content: '\2715';
}

/* '✕' */
.fontello-icon-cancel-4:before {
  content: '\e911';
}

/* '' */
.fontello-icon-cancel-circle-2:before {
  content: '\e87c';
}

/* '' */
.fontello-icon-attention-circle:before {
  content: '\2757';
}

/* '❗' */
.fontello-icon-minus-circle:before {
  content: '\2796';
}

/* '➖' */
.fontello-icon-left-circle-1:before {
  content: '\e958';
}

/* '' */
.fontello-icon-right-circle-1:before {
  content: '\e959';
}

/* '' */
.fontello-icon-right-bold:before {
  content: '\e4ae';
}

/* '' */
.fontello-icon-down-bold:before {
  content: '\e4b0';
}

/* '' */
.fontello-icon-info-circle:before {
  content: '\e705';
}

/* '' */
.fontello-icon-tag:before {
  content: '\e70c';
}

/* '' */
.fontello-icon-export-1:before {
  content: '\e820';
}

/* '' */
.fontello-icon-print-1:before {
  content: '\e822';
}

/* '' */
.fontello-icon-print-2:before {
  content: '\e919';
}

/* '' */
.fontello-icon-location-1:before {
  content: '\e827';
}

/* '' */
.fontello-icon-basket-1:before {
  content: '\e82c';
}

/* '' */
.fontello-icon-resize-small:before {
  content: '\e746';
}

/* '' */
.fontello-icon-popup-4:before {
  content: '\e954';
}

/* '' */
.fontello-icon-database:before {
  content: '\e754';
}

/* '' */
.fontello-icon-down-open-1:before {
  content: '\e836';
}

/* '' */
.fontello-icon-right-open:before {
  content: '\e75e';
}

/* '' */
.fontello-icon-right-open-1:before {
  content: '\e838';
}

/* '' */
.fontello-icon-bat-full-1:before {
  content: '\e8e0';
}

/* '' */
.fontello-icon-inbox-1:before {
  content: '\e848';
}

/* '' */
.fontello-icon-list-2:before {
  content: '\e8b8';
}

/* '' */
.fontello-icon-list-3:before {
  content: '\e95f';
}

/* '' */
.fontello-icon-dot:before {
  content: '\e78b';
}

/* '' */
.fontello-icon-off:before {
  content: '\e78e';
}

/* '' */
.fontello-icon-flow-tree:before {
  content: '\e9b2';
}

/* '' */
.fontello-icon-barcode:before {
  content: '\e792';
}

/* '' */
.fontello-icon-tint:before {
  content: '\e794';
}

/* '' */
.fontello-icon-tablet:before {
  content: '\e7b1';
}

/* '' */
.fontello-icon-puzzle:before {
  content: '\e7b6';
}

/* '' */
.fontello-icon-emo-happy:before {
  content: '\e8ed';
}

/* '' */
.fontello-icon-fire-station:before {
  content: '\ea0e';
}

/* '' */
.fontello-icon-garden:before {
  content: '\ea0b';
}

/* '' */
.fontello-icon-industrial-building:before {
  content: '\ea04';
}

/* '' */
.fontello-icon-tree-1:before {
  content: '\ea26';
}

/* '' */
.fontello-icon-check-empty:before {
  content: '\f096';
}

/* '' */
.fontello-icon-filter:before {
  content: '\f0b0';
}

/* '' */
.fontello-icon-money:before {
  content: '\f0d6';
}

/* '' */
.fontello-icon-twitter:before {
  content: '\f304';
}

/* '' */
.fontello-icon-facebook:before {
  content: '\f308';
}

/* '' */
.fontello-icon-skype:before {
  content: '\f30b';
}

/* '' */
.fontello-icon-linkedin:before {
  content: '\f30c';
}

/* '' */
.fontello-icon-youtube:before {
  content: '\f313';
}

/* '' */
.fontello-icon-award:before {
  content: '🏉';
}

/* '\1f3c9' */
.fontello-icon-award-2:before {
  content: '\e909';
}

/* '' */
.fontello-icon-thumbs-up:before {
  content: '👍';
}

/* '\1f44d' */
.fontello-icon-thumbs-up-1:before {
  content: '\e81c';
}

/* '' */
.fontello-icon-thumbs-down:before {
  content: '👎';
}

/* '\1f44e' */
.fontello-icon-basket:before {
  content: '\e73d';
}

/* '' */
.fontello-icon-user-4:before {
  content: '\e8ec';
}

/* '' */
.fontello-icon-credit-card:before {
  content: '💳';
}

/* '\1f4b3' */
.fontello-icon-monitor:before {
  content: '💻';
}

/* '\1f4bb' */
.fontello-icon-cd:before {
  content: '💿';
}

/* '\1f4bf' */
.fontello-icon-doc-text:before {
  content: '📄';
}

/* '\1f4c4' */
.fontello-icon-chart-bar-4:before {
  content: '\e9a6';
}

/* '' */
.fontello-icon-chart-bar:before {
  content: '📊';
}

/* '\1f4ca' */
.fontello-icon-chart-bar-1:before {
  content: '\e879';
}

/* '' */
.fontello-icon-phone-2:before {
  content: '\e9d8';
}

/* '' */
.fontello-icon-signal:before {
  content: '\e770';
}

/* '' */
.fontello-icon-search:before {
  content: '🔍';
}

/* '\1f50d' */
.fontello-icon-search-4:before {
  content: '\e8e8';
}

/* '' */
.fontello-icon-lock-1:before {
  content: '\e817';
}

/* '' */
.fontello-icon-lock-3:before {
  content: '\e8a1';
}

/* '' */
.fontello-icon-wrench:before {
  content: '🔧';
}

/* '\1f527' */
.fontello-icon-wrench-2:before {
  content: '\e8fb';
}

/* '' */
.fontello-icon-truck:before {
  content: '🚚';
}

/* '\1f69a' */
.fontello-icon-instagram-filled:before {
  content: '\ea30';
}

/* '' */
.fontello-icon-menu:before {
  content: '\f0c9';
}

/* '' */
.fontello-icon-location-1:before {
  content: '\e827';
}

/* '' */
.fontello-icon-doc-1:before {
  content: '\e829';
}

/* '' */
.fontello-icon-mail-1:before {
  content: '\e801';
}

/* '' */
.fontello-icon-phone-1:before {
  content: '\e8ac';
}

/* '' */
/* ==========================================================================
  FORM GROUP
========================================================================== */
.form-group {
  margin-bottom: 15px;
}
.form-group input {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
  padding: 10px;
  display: block;
  width: 100%;
}
.form-group input:focus {
  border-color: #428bca;
  outline: 0;
}
.form-group label {
  font-size: 13px;
}

html input[disabled], html input[disabled]:hover {
  cursor: not-allowed;
  background-color: #EEE;
  opacity: 1;
  border: 1px solid #ccc;
  box-shadow: none;
  color: #999;
}

html input[readonly] {
  cursor: not-allowed;
  background-color: #EEE;
  opacity: 1;
  border: 1px solid #EEE;
  box-shadow: none;
}

/* ==========================================================================
  FORM HORIZONTAL
========================================================================== */
.form-horizontal {
  margin-bottom: 15px;
  overflow: hidden;
  font-size: 13px;
}
.form-horizontal input[type=text], .form-horizontal input[type=password], .form-horizontal textarea {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
  padding: 10px;
  display: block;
  width: 100%;
}
.form-horizontal input[type=text]:focus, .form-horizontal input[type=password]:focus, .form-horizontal textarea:focus {
  border-color: #428bca;
  outline: 0;
}
.form-horizontal .form-flex-2col {
  margin-top: 15px;
}
.form-horizontal .form_dica {
  font-size: 11px;
  color: #999;
  margin-top: 5px;
}
.form-horizontal label.radio-inline, .form-horizontal label.control-label {
  display: block;
}
.form-horizontal label {
  display: block;
}
@media (min-width: 880px) {
  .form-horizontal .form-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .form-horizontal label {
    float: left;
    width: 160px;
    text-align: left;
  }
  .form-horizontal label.radio-inline-sm {
    width: 80px;
  }
  .form-horizontal .form-flex-2col {
    margin-top: 5px;
  }
  .form-horizontal .form-flex-2col > label {
    width: auto;
  }
  .form-horizontal input[type=text], .form-horizontal input[type=password], .form-horizontal textarea, .form-horizontal select {
    float: left;
    width: auto;
  }
}

.form-horizontal-sidebar {
  margin-bottom: 15px;
  overflow: hidden;
  font-size: 13px;
}
.form-horizontal-sidebar input[type=text], .form-horizontal-sidebar input[type=password], .form-horizontal-sidebar textarea {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
  padding: 10px;
  display: block;
  width: 100%;
}
.form-horizontal-sidebar input[type=text]:focus, .form-horizontal-sidebar input[type=password]:focus, .form-horizontal-sidebar textarea:focus {
  border-color: #428bca;
  outline: 0;
}
.form-horizontal-sidebar .form-flex-2col {
  margin-top: 15px;
}
.form-horizontal-sidebar .form_dica {
  font-size: 11px;
  color: #999;
  margin-top: 5px;
}
.form-horizontal-sidebar label.radio-inline, .form-horizontal-sidebar label.control-label {
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .form-horizontal-sidebar .form-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .form-horizontal-sidebar label {
    float: left;
    width: 160px;
    text-align: left;
  }
  .form-horizontal-sidebar label.radio-inline, .form-horizontal-sidebar label.control-label {
    display: inline-block;
    margin-bottom: 0;
  }
  .form-horizontal-sidebar .form-flex-2col {
    margin-top: 5px;
  }
  .form-horizontal-sidebar .form-flex-2col > label {
    width: auto;
  }
  .form-horizontal-sidebar input[type=text], .form-horizontal-sidebar input[type=password], .form-horizontal-sidebar textarea, .form-horizontal-sidebar select {
    float: left;
    max-width: 70%;
    width: auto;
  }
}

.success > .form-flex > .form_dica, .error > .form-flex > .form_dica {
  display: none;
}

.validacao_form_error, .validacao_form_sucess {
  display: none;
}

@media (min-width: 880px) {
  .validacao_form_error, .validacao_form_sucess {
    display: none;
  }

  .error > .form-flex > .validacao_form_error {
    display: block;
    color: #C00;
  }

  .success > .form-flex > .validacao_form_sucess {
    display: block;
    color: #2C9A00;
  }
}
.error > .form-flex > input, input.error, .error textarea, textarea.error {
  border: solid 1px #C00;
  color: #C00;
}

.success > .form-flex > input, .success > .form-flex > textarea {
  border: solid 1px #2C9A00;
  color: #2C9A00;
}

#login-form .has-error {
  margin: 0;
}

.link-form {
  float: right;
  margin-top: 5px;
}
@media (min-width: 880px) {
  .link-form {
    margin-top: 0;
    float: none;
  }
}

/* ==========================================================================
  FORM GROUP NEW
========================================================================== */
.form-group-new {
  margin-bottom: 20px;
}
.form-group-new input {
  background-color: #f4f4f4;
  border-bottom: 2px solid #cccccc;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  padding: 10px 15px;
  display: block;
  width: 100%;
}
.form-group-new input:focus {
  border-color: #428bca;
  outline: 0;
}

/* ==========================================================================
  DATATABLE
========================================================================== */
.dataTables_filter input {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
  padding: 10px;
  display: block;
  width: 100%;
}
.dataTables_filter input:focus {
  border-color: #428bca;
  outline: 0;
}

#table_catalogo_de_produtos_wrapper {
  font-size: 12px;
}
#table_catalogo_de_produtos_wrapper th {
  font-size: 12px;
}

/* ==========================================================================
  RADIO
========================================================================== */
.radio {
  margin-bottom: 15px;
}
.radio label {
  display: flex;
  align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 0 5px 0 0;
  height: 14px;
  width: 14px;
  float: left;
}

.valor-frete input[type="radio"],
.valor-frete input[type="checkbox"] {
  margin-top: 4px;
}

.checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin: 5px 0;
}
.checkbox input {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
.checkbox label {
  font-weight: 400;
}

select {
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
}

/* ==========================================================================
  ALERTS
========================================================================== */
.errorMessage {
  display: block;
  clear: both;
  color: #C00;
  font-size: 11px;
  padding-top: 5px;
}
@media (min-width: 880px) {
  .errorMessage {
    margin-left: 170px;
  }
}

.errorSummary ul {
  color: #a94442;
  background-color: #f2dede;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  list-style: inside;
}

.has-error {
  display: block;
  color: #C00;
  font-size: 11px;
}

.alert {
  padding: 10px;
  margin: 0 0 20px 0;
  border: 1px solid transparent;
  border-radius: 4px;
}

.home > .alert {
  margin-top: 0;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #bfd8ab;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

/* ==========================================================================
  BOTÃO
========================================================================== */
.btn {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
@media (min-width: 640px) {
  .btn {
    display: inline-block;
    width: auto;
  }
}
.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover, .btn:focus {
  color: #333;
  text-decoration: none;
}
.btn i {
  font-size: 14px;
  margin-left: 6px;
  margin-right: -6px;
}
.btn i.icon-right {
  margin-left: -2px;
  margin-right: 2px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.btn-form-first {
  margin-bottom: 10px;
}
@media (min-width: 640px) {
  .btn-form-first {
    float: right;
    margin-bottom: 0;
  }
}

/* ======== BTN DEFAULT ====================== */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #cccccc;
}
.btn-default:hover, .btn-default:focus {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
}

/* ======== BTN ATIVO ====================== */
.btn-ativo {
  background-color: #ebebeb;
  border-color: #adadad;
}

/* ======== BTN PRIMARY ====================== */
.btn-primary {
  color: #fff;
  background-color: #FA5B25;
  border-color: #f94407;
}
.btn-primary:hover, .btn-primary:focus {
  color: #fff;
  background-color: #fa5016;
  border-color: #f94407;
}

/* ======== BTN SUCESS ====================== */
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:hover, .btn-success:focus {
  color: #fff;
  background-color: #47a447;
  border-color: #398439;
}

/* ======== BTN INFO ====================== */
.btn-info {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-info:hover, .btn-info:focus {
  color: #fff;
  background-color: #357ebd;
  border-color: #3071a9;
}

/* ======== BTN WARNING ====================== */
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:hover, .btn-warning:focus {
  color: #fff;
  background-color: #ed9c28;
  border-color: #d58512;
}

/* ======== BTN DANGER ====================== */
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:hover, .btn-danger:focus {
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}

/* ======== BTN LINK ====================== */
.btn-link {
  font-weight: normal;
  color: #428bca;
  cursor: pointer;
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
}
.btn-link:hover, .btn-link:focus {
  color: #2a6496;
  text-decoration: underline;
  background-color: transparent;
}

@media (min-width: 480px) {
  .btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

/* Button */
.red.btn {
  color: #FFFFFF;
  background-color: #cb5a5e;
}

.red.btn:hover, .red.btn:focus, .red.btn:active, .red.btn.active {
  color: #FFFFFF;
  background-color: #c23f44;
}

.open .red.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #c23f44;
}

.red.btn:active, .red.btn.active {
  background-image: none;
  background-color: #b0383c;
}

.red.btn:active:hover, .red.btn.active:hover {
  background-color: #b83a3e;
}

.open .red.btn.dropdown-toggle {
  background-image: none;
}

.red.btn.disabled, .red.btn.disabled:hover, .red.btn.disabled:focus, .red.btn.disabled:active, .red.btn.disabled.active, .red.btn[disabled], .red.btn[disabled]:hover, .red.btn[disabled]:focus, .red.btn[disabled]:active, .red.btn[disabled].active, fieldset[disabled] .red.btn, fieldset[disabled] .red.btn:hover, fieldset[disabled] .red.btn:focus, fieldset[disabled] .red.btn:active, fieldset[disabled] .red.btn.active {
  background-color: #cb5a5e;
}

.red.btn .badge {
  color: #cb5a5e;
  background-color: #FFFFFF;
}

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: fontello;
  display: inline-block;
  content: "\e804";
}
@media (min-width: 880px) {
  .rating > label:before {
    font-size: 2.25em;
  }
}

.rating > label {
  color: #ddd;
  float: right;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #f0ad4e;
  cursor: pointer;
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #f0ad4e;
  cursor: pointer;
}

.row {
  display: table;
}

.col {
  display: table-cell;
  padding: 0px;
  vertical-align: middle;
}

.form-horizontal.termos {
  margin-bottom: 0;
}
.form-horizontal.termos label {
  display: none;
}
@media (min-width: 880px) {
  .form-horizontal.termos label {
    display: inline-block;
  }
}

.termos-de-uso-cadastro {
  font-size: 13px;
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  .termos-de-uso-cadastro .btn {
    width: 40%;
  }
}
@media (min-width: 880px) {
  .termos-de-uso-cadastro {
    display: flex;
    align-items: center;
  }
  .termos-de-uso-cadastro .leia-nosso {
    float: left;
    width: 170px;
  }
  .termos-de-uso-cadastro .btn {
    width: auto;
  }
}

/* ==========================================================================
  TYPOGRAPHY
========================================================================== */
p {
  margin: 0 0 15px;
}

/* ==========================================================================
  HEADERS
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 3px;
}

h2 {
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0 15px 0;
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 3px;
}

h3 {
  color: #FA5B25;
  font-weight: bold;
  margin: 0 0 10px 0;
  font-size: 16px;
}

.sidebar .titulo-sidebar {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px 0;
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 5px;
  color: #404040;
}
.sidebar .titulo-sidebar i {
  padding-right: 5px;
}

small,
.small {
  font-size: 85%;
  margin-top: 5px;
  display: inline-block;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 30px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.42857143;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999;
  text-decoration: none;
}

blockquote {
  padding-left: 10px;
  margin: 0 0 20px 0;
  font-size: 17.5px;
  border-left: 5px solid #eee;
  line-height: 28px;
}
@media (min-width: 640px) {
  blockquote {
    margin-left: 20px;
  }
}
blockquote p {
  margin-bottom: 0;
}

blockquote:before,
blockquote:after {
  content: "";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

pre {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* ==========================================================================
  TABLES
========================================================================== */
table {
  width: 100%;
}

tbody > tr:first-child {
  border-top: 1px solid #ccc;
}

tbody > tr:hover {
  background: #f2f2f2;
}

th {
  padding: 8px;
  vertical-align: top;
  text-align: left;
  font-size: 16px;
}

td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

/* ==========================================================================
  TABLES DESCRIÇÃO PRODUTO SINGLE
========================================================================== */
#tab-descricao table {
  margin-bottom: 20px;
}
#tab-descricao tbody > tr {
  border: 0;
}
#tab-descricao tbody > tr:first-child {
  border-top: none;
}
#tab-descricao tbody > tr:hover {
  background: none;
}
#tab-descricao tbody > tr > td {
  border: 0;
  padding: 0 0 15px 0;
  vertical-align: top;
}
#tab-descricao tbody > tr > td p {
  margin: 0 0 10px 0;
}
@media (min-width: 560px) {
  #tab-descricao tbody > tr > :first-child:nth-last-child(2),
  #tab-descricao tbody > tr > :first-child:nth-last-child(2) ~ td {
    width: 50%;
    padding: 0 15px;
  }
  #tab-descricao tbody > tr > :first-child:nth-last-child(3),
  #tab-descricao tbody > tr > :first-child:nth-last-child(3) ~ td {
    width: 33.3333%;
    padding: 0 15px;
  }
  #tab-descricao tbody > tr > :first-child:nth-last-child(4),
  #tab-descricao tbody > tr > :first-child:nth-last-child(4) ~ td {
    width: 25%;
  }
}

/* ==========================================================================
  TABLES - RESPONSIVE
========================================================================== */
@media only screen and (max-width: 560px), (min-device-width: 480px) and (max-device-width: 560px) {
  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr, caption {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
  Label the data
  */
  td:before {
    content: attr(data-label);
  }
}
.colunas-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.colunas-flex > * {
  flex: 1;
}

.colunas-flex-2 {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 10px;
}
@media (min-width: 480px) {
  .colunas-flex-2 {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.colunas-flex-3 {
  display: grid;
  grid-template-columns: 1fr;
  align-items: top;
  grid-gap: 10px;
}
@media (min-width: 640px) {
  .colunas-flex-3 {
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.colunas-flex-3 .box {
  margin-bottom: 0;
}

.colunas-flex-4 {
  display: grid;
  grid-template-columns: 1fr;
  align-items: top;
  grid-gap: 10px;
}
@media (min-width: 640px) {
  .colunas-flex-4 {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .colunas-flex-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
.colunas-flex-4 .box {
  margin-bottom: 0;
}

.colunas-flex-6 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: top;
  grid-gap: 10px;
}
@media (min-width: 480px) {
  .colunas-flex-6 {
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 760px) {
  .colunas-flex-6 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .colunas-flex-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1200px) {
  .colunas-flex-6 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1450px) {
  .colunas-flex-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.colunas-flex-6 .box {
  margin-bottom: 0;
}

.colunas-flex-3-selos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: top;
  grid-gap: 10px;
}
@media (min-width: 480px) {
  .colunas-flex-3-selos {
    grid-gap: 15px;
  }
}

.colunas:after {
  /* Or @extend clearfix */
  content: "";
  display: table;
}

[class*='col-'] {
  float: left;
}
[class*='col-']:last-of-type {
  padding-right: 0;
}

.col-1-2 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 1024px) {
  .col-1-2 {
    width: 50%;
    padding-right: 20px;
  }
}

.col-1-3 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-1-3 {
    width: 33.33%;
    padding-right: 20px;
  }
}

.col-sm-1-4 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 640px) {
  .col-sm-1-4 {
    width: 25%;
    padding-right: 20px;
  }
}

.col-1-4 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 640px) {
  .col-1-4 {
    width: 50%;
    padding-right: 20px;
  }
  .col-1-4:nth-child(even) {
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .col-1-4 {
    width: 25%;
    padding-right: 20px;
  }
  .col-1-4:nth-child(even) {
    padding-right: 20px;
  }
  .col-1-4:last-of-type {
    padding-right: 0;
  }
}

.col-1-5 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 480px) {
  .col-1-5 {
    width: 50%;
    padding-right: 20px;
  }
}
@media (min-width: 640px) {
  .col-1-5 {
    width: 33%;
    padding-right: 20px;
  }
}
@media (min-width: 880px) {
  .col-1-5 {
    width: 20%;
    padding-right: 20px;
  }
}

.col-1-5-box-comprar-rightbar {
  width: 100%;
  padding-right: 0;
  max-height: 500px;
}
@media (min-width: 1200px) {
  .col-1-5-box-comprar-rightbar {
    width: 30%;
    padding-right: 20px;
  }
}
@media (min-width: 1600px) {
  .col-1-5-box-comprar-rightbar {
    width: 20%;
    padding-right: 20px;
  }
}

.col-2-3 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-2-3 {
    width: 66.66%;
    padding-right: 20px;
  }
}

.col-2-4 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-2-4 {
    width: 50%;
    padding-right: 20px;
  }
}

.col-3-4 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-3-4 {
    width: 75%;
    padding-right: 20px;
  }
}

.col-4-5 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-4-5 {
    width: 80%;
    padding-right: 20px;
  }
}

.col-4-6-video-produtosingle {
  width: 100%;
}
@media (min-width: 880px) {
  .col-4-6-video-produtosingle {
    padding-right: 15% !important;
    padding-left: 15%;
  }
}

.col-4-5-produtoSingle {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 1200px) {
  .col-4-5-produtoSingle {
    width: 70%;
    padding-right: 40px;
  }
}
@media (min-width: 1600px) {
  .col-4-5-produtoSingle {
    width: 80%;
    padding-right: 40px;
  }
}
.col-4-5-produtoSingle h2:first-child {
  margin-top: 0;
}

.col-1-8 {
  width: 100%;
  padding-right: 0;
}
@media (min-width: 880px) {
  .col-1-8 {
    width: 12.5%;
    padding-right: 20px;
  }
}

/* Opt-in outside padding */
.colunas-pad {
  padding: 20px 0 20px 20px;
}
.colunas-pad [class*='col-']:last-of-type {
  padding-right: 20px;
}

.tooltip {
  position: fixed;
  z-index: 1070;
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  visibility: visible;
  filter: alpha(opacity=0);
  opacity: 0;
}

.tooltip.in {
  filter: alpha(opacity=100);
  opacity: 1;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 380px;
  padding: 5px 8px;
  color: #666;
  text-align: center;
  text-decoration: none;
  background-color: #f2f2f2;
  border-radius: 4px;
  white-space: pre-wrap;
  border: solid 1px #ccc;
  box-shadow: #bababa 0 2px 0;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #ccc;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: #ccc;
}

.tooltip.top-right .tooltip-arrow {
  right: 5px;
  bottom: 0;
  border-width: 5px 5px 0;
  border-top-color: #ccc;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #ccc;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #ccc;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #ccc;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #ccc;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.modal-open {
  overflow: hidden;
}
.modal-open #calculo-preco {
  right: 9px;
}
.modal-open .header-scroll {
  padding-right: 17px;
}

.modal {
  position: fixed;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .6;
}

.modal-header {
  min-height: 16.42857143px;
  margin: 15px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}

.modal-subtitle {
  line-height: 1.42857143;
  font-size: 18px;
  margin: 40px 0 10px 0;
  border-bottom: solid 1px #e5e5e5;
}

.modal-body {
  position: relative;
  margin: 0 20px;
}

.modal-footer {
  margin: 15px 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
  overflow: hidden;
}

.modal-footer .btn + .btn {
  margin-top: 10px;
}
@media (min-width: 640px) {
  .modal-footer .btn + .btn {
    margin-top: 0;
  }
}

@media (min-width: 640px) {
  .modal-footer .modal-cancelar {
    float: left;
  }
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}

.panel-body ul {
  margin-bottom: 0;
}

.panel-heading {
  padding: 6px 10px;
  border-bottom: 1px dotted #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  color: inherit;
}

h2.panel-title {
  border: none;
}

.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
  display: block;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border: solid 1px #cccccc;
}

.panel-default > .panel-heading {
  background-color: #f2f2f2;
  color: #404040;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #337ab7;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}

.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0;
  }

  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }

  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}

.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
}

.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #337ab7;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #286090;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover,
a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #337ab7;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #286090;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0;
  }

  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }

  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}

.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
}

.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #337ab7;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #286090;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

.label {
  display: inline;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

/* ==========================================================================
  OFF CANVAS
  ========================================================================== */
.offcanvas {
  display: inherit;
  position: fixed;
  top: 0;
  z-index: 1050;
  background-color: #fff;
  height: 100%;
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.4);
  width: 100%;
  min-width: 303px;
  outline: 0;
  font-size: 12px;
  overflow: scroll;
}
.offcanvas .offcanvas-usuario {
  background-color: #1b1811;
  padding: 15px;
  display: flex;
  align-items: center;
  color: #fff;
  line-height: 15px;
  border-bottom: solid 1px #4a422f;
}
.offcanvas .offcanvas-usuario .fontello-icon-cancel-4 {
  font-size: 22px;
  color: #fff;
}
.offcanvas .offcanvas-usuario .fontello-icon-user-4 {
  font-size: 26px;
  margin-right: 5px;
}
.offcanvas .offcanvas-usuario .fontello-icon-user-4.user-logado {
  color: #2C9A00;
}
.offcanvas .offcanvas-usuario a {
  color: #f0ad4e;
  font-size: 11px;
}
.offcanvas .offcanvas-location {
  border-bottom: solid 1px #cccccc;
}
.offcanvas .offcanvas-location .calculo-preco {
  padding: 10px 15px;
  display: flex;
  justify-self: start;
  align-items: center;
  text-align: left;
  width: 100%;
  background-color: #f2f2f2;
  border: 0;
  color: #404040;
}
.offcanvas .offcanvas-location i.fontello-icon-location-1 {
  font-size: 22px;
}
.offcanvas .offcanvas-location span.location-estado-tipo-empresa {
  font-size: 10px;
}
.offcanvas .offcanvas-location span.location-estado-tipo-empresa strong {
  color: #428bca;
}
.offcanvas .offcanvas-central-atendimento {
  padding: 15px;
  font-size: 14px;
}
.offcanvas .offcanvas-central-atendimento a[role=button] {
  display: block;
  background-color: #2C9A00;
  padding: 6px 12px;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
}
.offcanvas .offcanvas-central-atendimento .fontello-icon-down-open-1 {
  float: right;
  padding-top: 3px;
  font-size: 11px;
}
.offcanvas .offcanvas-central-atendimento .central-atendimento-conteudo {
  margin-top: 15px;
  font-size: 12px;
}
.offcanvas .offcanvas-central-atendimento .atendimento-horario {
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0;
}
.offcanvas .offcanvas-central-atendimento .atendimento-left {
  line-height: 20px;
  width: 100%;
  margin-top: 10px;
}
.offcanvas .offcanvas-central-atendimento .atendimento-left ul.telefones {
  list-style: none;
  padding: 0;
  overflow: hidden;
  border-right: none;
  padding: 0;
}
.offcanvas .offcanvas-central-atendimento .atendimento-left ul.telefones li {
  float: left;
}
.offcanvas .offcanvas-central-atendimento .atendimento-left ul.telefones li:nth-child(odd) {
  margin-right: 1%;
  padding-right: 1%;
  width: 48%;
  border-right: dotted 1px #cccccc;
}
.offcanvas .offcanvas-central-atendimento .atendimento-left ul.telefones li a {
  color: #428bca;
}
.offcanvas .offcanvas-menu {
  padding: 0 15px;
  font-size: 13px;
}
.offcanvas .offcanvas-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: dotted 1px #cccccc;
}
.offcanvas .offcanvas-menu ul li {
  border-bottom: dotted 1px #cccccc;
}
.offcanvas .offcanvas-menu ul li a {
  display: block;
  padding: 10px 0;
}

/* ==========================================================================
  COLLAPSE
  ========================================================================== */
.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .01s;
  -o-transition-duration: .01s;
  transition-duration: .01s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
}

.header-leitores-hover, .header-impressoras-hover, .header-coletor-hover, .header-automacao-hover, .header-etiquetas-hover, .header-ribbons-hover, .header-informatica-hover, .header-pecas-hover {
  display: none;
}

.header-categoria-leitores:hover .header-leitores-hover {
  display: block;
}

.header-categoria-impressoras:hover .header-impressoras-hover {
  top: 30px;
  display: block;
}

.header-categoria-coletor:hover .header-coletor-hover {
  top: 60px;
  display: block;
}

.header-categoria-automacao:hover .header-automacao-hover {
  top: 90px;
  display: block;
}

.header-categoria-etiquetas:hover .header-etiquetas-hover {
  top: 120px;
  display: block;
}

.header-categoria-ribbons:hover .header-ribbons-hover {
  top: 150px;
  display: block;
}

.header-categoria-informatica:hover .header-informatica-hover {
  top: 180px;
  display: block;
}

.header-categoria-pecas:hover .header-pecas-hover {
  top: 210px;
  display: block;
}

.central-atendimento-hover ul.telefones {
  display: none;
}
@media (min-width: 1024px) {
  .central-atendimento-hover {
    position: absolute;
    width: 430px;
    left: -63px;
    top: 29px;
    background-color: #f2f2f2;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
    text-align: left;
    color: #404040;
  }
  .central-atendimento-hover a:hover {
    color: #1d7e1d;
  }
  .central-atendimento-hover .box-hover {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 4px;
    padding: 15px;
  }
  .central-atendimento-hover .hover-titulo {
    color: #e47911;
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 16px;
  }
  .central-atendimento-hover .atendimento-email {
    clear: both;
    display: block;
    background-color: #f2f2f2;
    padding: 5px 15px;
    margin: 0 -15px 15px -15px;
  }
  .central-atendimento-hover .atendimento-horario {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 0;
  }
  .central-atendimento-hover .atendimento-left {
    line-height: 18px;
    width: 100%;
  }
  .central-atendimento-hover .atendimento-left ul.telefones {
    display: block;
    list-style: none;
    padding: 0;
    overflow: hidden;
    border-right: none;
    padding: 0;
  }
  .central-atendimento-hover .atendimento-left ul.telefones li {
    float: left;
  }
  .central-atendimento-hover .atendimento-left ul.telefones li:nth-child(odd) {
    margin-right: 15px;
    width: 192px;
    border-right: dotted 1px #cccccc;
  }
}

.header-usuario-hover {
  position: absolute;
  background-color: #fff;
  padding: 7px 15px 15px 15px;
  z-index: 10;
  width: 100%;
  top: 35px;
  left: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  text-align: left;
  color: #1b1811;
}
.header-usuario-hover a.btn {
  color: #fff;
  margin: 7px 0 12px 0;
}
.header-usuario-hover span > a {
  color: #428bca;
}
.header-usuario-hover ul {
  padding: 0;
  list-style: none;
  line-height: 28px;
  margin-bottom: 0;
}
.header-usuario-hover ul li {
  border-bottom: dotted 1px #cccccc;
}
.header-usuario-hover ul li a {
  display: block;
  color: #428bca;
}
.header-usuario-hover ul li a:hover {
  background-color: #f2f2f2;
}

.header-categorias-hover {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  width: 250px;
  top: 31px;
  left: 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  text-align: left;
  color: #333;
  font-size: 12px;
}
.header-categorias-hover h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #ccc;
  color: #404040;
}
.header-categorias-hover h3 div.categoria-link {
  display: block;
  font-size: 12px;
  color: #428bca;
}
.header-categorias-hover h3 div.categoria-link i {
  color: #404040;
  padding-right: 5px;
}
.header-categorias-hover h3 .fontello-icon-minus, .header-categorias-hover h3 .fontello-icon-plus {
  font-size: 10px;
  color: #ccc;
  padding-top: 4px;
}
.header-categorias-hover .panel-body {
  padding: 12px 16px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.4);
  position: absolute;
  background-color: #fff;
  left: 250px;
  top: 0;
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #428bca;
  min-width: 700px;
}
.header-categorias-hover .panel-body ul {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}
.header-categorias-hover .panel-body li {
  line-height: 20px;
}
.header-categorias-hover .panel-body a {
  color: #333;
  display: block;
  font-size: 12px;
}

.leftbar__subtitulo2 {
  font-weight: 700;
  margin: 0 0 6px 0;
  padding: 3px 0;
  border-bottom: dotted 1px #ccc;
}

.whatsapp {
  background-color: #edf8f5;
  margin: 10px 0;
  color: #1d7e1d;
  position: relative;
  padding: 5px 10px 5px 36px;
  border-radius: 4px;
  border: 1px solid #1ebea5;
}
.whatsapp .icone-whatsapp {
  position: absolute;
  left: 6px;
  top: 3px;
}

.diferencias {
  background-color: #f2f2f2;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.diferencias-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  gap: 30px;
}
.diferencias-flex .colunas-diferenciais {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  flex: 80%;
}
.diferencias-flex .colunas-diferenciais i {
  display: inline-block;
  background-color: #FFFFFF;
  border-radius: 100%;
  border: 1px solid #CCCCCC;
  padding: 0.6em 0.5em 0.5em 0.7em;
  font-size: 28px;
  color: #FA5B25;
}
@media (min-width: 640px) {
  .diferencias-flex .colunas-diferenciais i {
    font-size: 38px;
  }
}
@media (min-width: 640px) {
  .diferencias-flex .colunas-diferenciais {
    flex: 40%;
  }
}
@media (min-width: 1200px) {
  .diferencias-flex .colunas-diferenciais {
    flex: 20%;
  }
  .diferencias-flex .colunas-diferenciais span {
    font-size: 16px;
  }
}
@media (min-width: 1600px) {
  .diferencias-flex .colunas-diferenciais {
    flex-direction: row;
  }
}

.container-fabricantes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 100%;
  gap: 30px;
}
.container-fabricantes a {
  display: flex;
  justify-content: center;
  flex: 25%;
  transition: 0.3s ease;
}
@media (min-width: 640px) {
  .container-fabricantes a {
    flex: 12%;
  }
  .container-fabricantes a img {
    width: auto;
    height: auto;
  }
}
.container-fabricantes a:hover {
  transform: scale(1.1);
}

/* ==========================================================================
  CONFIG - GERAL
  ========================================================================== */
.relative {
  position: relative;
}

.block {
  display: block;
}

.right {
  float: right;
}

p.right {
  float: right;
  margin-bottom: 0;
}

.left {
  float: left;
}

.clear {
  clear: both;
}

.overflow {
  overflow: hidden;
}

.bigger {
  font-size: 18px;
  font-weight: 700;
}

.linha-block {
  margin-bottom: 30px;
  overflow: hidden;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.success {
  color: #2C9A00;
}

.danger {
  color: #C00;
}

.mb-0 {
  margin-bottom: 0;
}

.box {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: #bababa 0 2px 0;
  padding: 15px;
}
.box h2 {
  margin-top: 0;
}
.box .header-box {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 700;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #404040;
  margin: 0 0 15px 0;
}
.box .header-box-top {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 700;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #404040;
  margin: -16px -16px 10px -16px;
}

.box-no-shadow {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}
.box-no-shadow h2 {
  margin-top: 0;
}
.box-no-shadow .header-box {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 700;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #404040;
  margin: 0 0 15px 0;
}
.box-no-shadow .header-box-top {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 700;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 6px 10px;
  color: #404040;
  margin: -11px -11px 10px -11px;
}

.ordenar {
  font-size: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  .ordenar {
    width: auto;
    float: right;
  }
}

.list-view .sorter li {
  margin: 0;
}
.list-view .sorter li a {
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  border: solid 1px #cccccc;
}
.list-view .sorter li a.asc, .list-view .sorter li a.desc {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding-right: 15px;
}

.list-view {
  padding: 0;
}

.titulo-top {
  margin-top: 0 !important;
}

.removeMobile {
  display: none;
}
@media (min-width: 760px) {
  .removeMobile {
    display: block;
  }
}

#calculoValorPublic .fontello-icon-help-circle {
  color: #428bca;
  font-size: 15px;
}
#calculoValorPublic .tooltip.in {
  filter: alpha(opacity=100);
  opacity: 1;
}

/* ==========================================================================
  HEADER SCROLL - HEADROOM
  ========================================================================== */
.headroom {
  display: none;
}
@media (min-width: 1024px) {
  .headroom {
    background-color: #1b1811;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    padding: 12px 0;
    color: #f2f2f2;
  }
  .headroom .wrapper {
    display: grid;
    grid-template-columns: 122px 1fr 1fr 85px;
    grid-column-gap: 20px;
  }
  .headroom .header-logo-scroll {
    background: url("../img/logo-bztech-mobile.svg") 0 0 no-repeat;
    width: 122px;
    height: 29px;
    margin: 0 auto;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
    margin-top: 4px;
  }
  .headroom .calculo-preco {
    display: flex;
    justify-self: start;
    align-items: center;
    text-align: left;
    width: 100%;
    background-color: #1b1811;
    border: 0;
    color: #f2f2f2;
    padding: 0;
    margin-top: 2px;
  }
  .headroom .header-carrinho {
    grid-area: unset;
    margin-top: 0;
    font-size: 12px;
  }
  .headroom i.fontello-icon-location-1 {
    font-size: 18px;
  }
  .headroom span.location-estado-tipo-empresa {
    font-size: 11px;
  }
  .headroom span.location-estado-tipo-empresa strong {
    color: #f0ad4e;
  }
}

.headroom--top {
  display: none;
}

@media (min-width: 1024px) {
  .headroom--not-top {
    display: block;
  }
}

/* ==========================================================================
  ABAS
  ========================================================================== */
.r-tabs {
  position: relative;
  font-size: 12px;
}

.r-tabs .r-tabs-nav {
  border-bottom: 1px solid #cccccc;
}

.r-tabs .r-tabs-nav .r-tabs-tab {
  position: relative;
  top: 1px;
}

.r-tabs .r-tabs-nav .r-tabs-anchor {
  background: #f2f2f2;
  margin-bottom: 6px;
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
  color: #404040;
  font-weight: bold;
  border-radius: 4px;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
}
.r-tabs .r-tabs-nav .r-tabs-anchor:hover {
  background: #ededed;
}

.r-tabs .r-tabs-nav .r-tabs-state-active {
  background-color: #fff;
  margin-bottom: -1px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
  color: #428bca;
  background-color: #fff;
  border: 0;
}

.r-tabs .r-tabs-panel {
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  margin-bottom: 6px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
  display: block;
  padding: 10px;
  background-color: #f2f2f2;
  color: #404040;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
  background-color: #fff;
  color: #428bca;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px dotted #cccccc;
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.r-tabs .r-tabs-nav {
  margin: 0;
  padding: 0;
  padding-left: 8px;
}

.r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0 2px;
  list-style: none;
}

.r-tabs .r-tabs-panel {
  padding: 15px;
  display: none;
}

.r-tabs .r-tabs-accordion-title {
  display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
  display: block;
}

@media only screen and (max-width: 768px) {
  .r-tabs .r-tabs-nav {
    display: none;
  }

  .r-tabs .r-tabs-accordion-title {
    display: block;
  }
}
/* ==========================================================================
  IMPORTS
  ========================================================================== */
@media (min-width: 760px) {
  .breadcrumb {
    display: block;
    background-color: #f2f2f2;
    border-bottom: solid 1px #cccccc;
    height: 30px;
    color: #666;
    font-size: 11px;
  }
  .breadcrumb .fontello-icon-right-open {
    font-size: 9px;
  }
  .breadcrumb a {
    color: #666;
  }
  .breadcrumb ul {
    list-style: none;
    padding-left: 0;
    padding-top: 8px;
  }
  .breadcrumb ul li {
    display: inline-block;
  }
}

a.todas-categorias {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-top: 20px;
  box-shadow: #bababa 0 2px 0;
  padding: 6px 12px;
  width: 100%;
  display: block;
  color: #666;
}
a.todas-categorias:hover {
  text-decoration: none;
  background-color: #f2f2f2;
}
a.todas-categorias .fontello-icon-right-open-1 {
  float: right;
  font-size: 11px;
  padding-top: 3px;
}
@media (min-width: 760px) {
  a.todas-categorias {
    display: none;
  }
}

#leftbar {
  font-size: 12px;
}
#leftbar .box {
  margin-bottom: 0;
}
#leftbar .panel-group {
  margin-bottom: 0;
}
#leftbar .panel-body {
  padding: 0 15px 15px 15px;
}
@media (min-width: 760px) {
  #leftbar {
    float: left;
    width: 280px;
    border-right: dotted 1px #cccccc;
    min-height: 800px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-bottom: 40px;
  }
  #leftbar .box {
    margin-right: 20px;
  }
}
#leftbar ul {
  list-style: none;
  padding: 0;
}
#leftbar ul li {
  line-height: 20px;
  position: relative;
  line-height: 26px;
}
#leftbar ul li a {
  display: block;
  color: #404040;
}
#leftbar ul li a:hover {
  color: #333333;
}
#leftbar li.active {
  padding-left: 10px;
  background-color: #f2f2f2;
}
#leftbar li.active:hover {
  color: #404040;
}
#leftbar .leftbar-desktop li.active:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: #FA5B25;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
#leftbar .leftbar-desktop ul.subnav-orcamento li.active:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: #FA5B25;
  content: "";
  left: 0;
  top: 5px;
  position: absolute;
}
#leftbar .subnav-orcamento {
  padding-left: 15px;
  margin-bottom: 5px;
}
#leftbar .subnav-orcamento li {
  line-height: 22px;
}
#leftbar #leftbar.sidebar > ul > li {
  line-height: 46px;
}
#leftbar .subnav {
  margin-bottom: 0;
}
#leftbar .subnav li {
  border-bottom: dotted 1px #cccccc;
  padding: 3px 0 0 10px;
}
#leftbar .subnav li.active:before {
  top: 7px;
}

#sidebar-home h3 {
  margin-bottom: 0;
  padding-bottom: 10px;
  margin-top: 10px;
}
#sidebar-home h3 a {
  display: block;
  font-size: 14px;
}
#sidebar-home h3 a i {
  color: #404040;
}
#sidebar-home h3:first-child {
  margin-top: 23px;
}
#sidebar-home h4 {
  background-color: #f2f2f2;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #666;
  padding: 5px 15px;
  font-size: 12px;
  margin-left: -15px;
}
#sidebar-home .fontello-icon-plus, #sidebar-home .fontello-icon-minus {
  font-size: 10px;
  color: #cccccc;
  padding-top: 4px;
}
#sidebar-home .panel-body h4:first-child {
  margin-top: 0;
}

#filtersLeftbar fieldset {
  display: block;
  border: 0;
  padding: 0;
}
#filtersLeftbar .checkbox {
  cursor: pointer;
  margin: 0 0 5px 0;
}
#filtersLeftbar .checkbox input[type="checkbox"] {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
  opacity: 0;
  z-index: 1;
}
#filtersLeftbar .checkbox label {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding-left: 26px;
  padding-top: 2px;
}
#filtersLeftbar .checkbox label:before,
#filtersLeftbar .checkbox label:after {
  content: '';
  display: block;
  position: absolute;
}
#filtersLeftbar .checkbox label:before {
  left: 0;
  top: 0;
  width: 16px;
  height: 15px;
  margin-right: 10px;
  background: #f2f2f2;
  border-radius: 4px;
  border: solid 1px #cccccc;
}
#filtersLeftbar .checkbox label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background: #FA5B25;
  opacity: 0;
  pointer-events: none;
}
#filtersLeftbar .checkbox input:checked ~ label:after {
  opacity: 1;
}

.container-blog {
  position: relative;
}
.container-blog .mix {
  display: none;
}
.container-blog .fail-message {
  margin-bottom: 10px;
  display: none;
}

.fail > .fail-message {
  display: block;
}

.leftbar-mobile ul li {
  border-bottom: dotted 1px #cccccc;
  padding: 5px 0;
}

.minha-conta-logout {
  border-top: 1px dotted #ccc;
  margin-top: 10px;
  padding-top: 10px;
}

@media (min-width: 1200px) {
  #rightbar {
    display: block;
    float: right;
    width: 190px;
    font-size: 12px;
    padding-top: 25px;
  }
}
#rightbar .icone-whatsapp {
  float: left;
  margin-right: 10px;
}
#rightbar ul > li, #rightbar ol > li {
  line-height: 20px;
}
#rightbar .atendimento-foto {
  margin-top: -10px;
  margin-bottom: 15px;
  border-bottom: dotted 1px #cccccc;
}
#rightbar .telefones-rightbar {
  list-style: none;
  padding: 0;
}
#rightbar .redes-sociais-rightbar {
  text-align: center;
}
#rightbar .redes-sociais-rightbar a {
  display: block;
}
#rightbar .redes-sociais-rightbar .googlemais-btn {
  padding-left: 40px;
  margin-top: 5px;
  margin-bottom: -5px;
}
#rightbar .redes-sociais-rightbar .fb-like {
  margin: 8px 0 1px 0;
}
#rightbar .redes-sociais-rightbar [class*='icon-logo-'] {
  width: 103px;
  margin: 0 auto;
}
#rightbar ul.top10 {
  font-size: 11px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
#rightbar ul.top10 li {
  border-bottom: dotted 1px #cccccc;
}
#rightbar ul.top10 li:hover {
  background: #f2f2f2;
}
#rightbar ul.top10 li a {
  color: #404040;
  display: block;
}
#rightbar ol.top10 {
  font-size: 11px;
  padding: 0 0 0 15px;
  margin-bottom: 0;
}
#rightbar ol.top10 li {
  border-bottom: dotted 1px #cccccc;
}
#rightbar ol.top10 li:hover {
  background: #f2f2f2;
}
#rightbar ol.top10 li a {
  color: #404040;
  display: block;
}
#rightbar .fontello-icon-skype {
  color: #428bca;
}

/* ==========================================================================
  DISPONIBILIDADE
  ========================================================================== */
.disponibilidade-estoque {
  font-size: 12px;
  color: #2C9A00;
}

.disponibilidade-encomenda {
  font-size: 12px;
  color: #C00;
}

.disponibilidade-a-caminho {
  font-size: 12px;
  color: #8a6d3b;
  background: #fcf8e3;
  border: 1px solid #ebd9b5;
  padding: 5px;
  border-radius: 4px;
}

.disponibilidade-ultimas-pecas {
  font-size: 12px;
  color: #666;
}

/* ==========================================================================
  LISTAGEM EM GRADE
  ========================================================================== */
.listagem-grid-produto {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.listagem-grid-produto [class*='grid-'] {
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #fff;
  border-bottom: 1px dotted #ccc;
}
.listagem-grid-produto [class*='grid-']:hover {
  border: solid 1px #cccccc;
  box-shadow: #bababa 0 2px 0;
}
@media (min-width: 480px) {
  .listagem-grid-produto {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 760px) {
  .listagem-grid-produto {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

.listagem-grid-download {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.listagem-grid-download [class*='grid-'] {
  border-radius: 4px;
  padding: 10px;
  border: 1px dotted #ccc;
}
.listagem-grid-download [class*='grid-']:hover {
  border: solid 1px #cccccc;
  box-shadow: #bababa 0 2px 0;
}
@media (min-width: 480px) {
  .listagem-grid-download {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 760px) {
  .listagem-grid-download {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .listagem-grid-download {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listagem-grid-fabricante {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.listagem-grid-fabricante [class*='grid-'] {
  border-radius: 4px;
  border: 1px solid #fff;
  border-bottom: 1px dotted #ccc;
  padding: 0 0 15px 0;
}
@media (min-width: 480px) {
  .listagem-grid-fabricante [class*='grid-'] {
    padding: 0 0 30px 0;
  }
}
.listagem-grid-fabricante [class*='grid-']:hover {
  border: solid 1px #cccccc;
  box-shadow: #bababa 0 2px 0;
}
.listagem-grid-fabricante [class*='grid-'] img, .listagem-grid-fabricante [class*='grid-'] span, .listagem-grid-fabricante [class*='grid-'] a {
  display: block;
}
.listagem-grid-fabricante [class*='grid-'] img {
  width: 80px;
  float: left;
  margin-right: 20px;
}
@media (min-width: 480px) {
  .listagem-grid-fabricante [class*='grid-'] img {
    width: 120px;
    float: none;
    margin: 0 auto;
  }
}
.listagem-grid-fabricante [class*='grid-'] .modal-fabricante-suporte a {
  display: inline-block;
}
@media (min-width: 480px) {
  .listagem-grid-fabricante {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
}
@media (min-width: 760px) {
  .listagem-grid-fabricante {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .listagem-grid-fabricante {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .listagem-grid-fabricante {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listagem-grid-blog {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.listagem-grid-blog [class*='grid-'] {
  padding-bottom: 15px;
  border-bottom: 1px dotted #ccc;
}
@media (min-width: 480px) {
  .listagem-grid-blog {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1450px) {
  .listagem-grid-blog {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .listagem-grid-blog [class*='grid-'] {
    padding-bottom: 20px;
  }
}

/* ==========================================================================
  PRODUTOS
  ========================================================================== */
#div_estrelas label {
  width: 45px;
}

.list-view .items {
  *zoom: 1;
}
.list-view .items:before, .list-view .items:after {
  content: " ";
  display: table;
}
.list-view .items:after {
  clear: both;
}
.list-view .pager .pagination {
  text-align: center;
}
.list-view .pager .pagination li {
  padding: 0 10px;
  display: inline;
  text-align: center;
}

.grid-produto {
  text-align: center;
}
.grid-produto .lazyload, .grid-produto span {
  display: block;
}
.grid-produto span.estrela {
  display: inline;
}
.grid-produto .lazyload {
  width: 180px;
  margin: 0 auto;
}

div.produto .list-codigo {
  font-size: 11px;
  color: #b3b3b3;
}
div.produto .list-avaliacoes {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 4px;
}
div.produto a.list-titulo {
  color: #404040;
  font-size: 17px;
  display: block;
  line-height: 22px;
  margin-bottom: 2px;
}
div.produto a.list-titulo:hover {
  cursor: pointer;
}
div.produto a.list-fabricante {
  display: none;
}
@media (min-width: 640px) {
  div.produto a.list-fabricante {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }
}
div.produto .list-preco {
  font-size: 18px;
  font-weight: 700;
  color: #2C9A00;
}
div.produto .mostra-estoque {
  display: none;
  margin-top: 20px;
  color: #C00;
  font-size: 12px;
}
div.produto .mostra > button {
  font-size: 80%;
}

.oferta-especial {
  background-color: #C00;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  float: left;
}
.oferta-especial .fontello-icon-fire-station {
  color: #f0ad4e;
  padding-right: 5px;
}

.queima-estoque-tag {
  background-color: #017fa3;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  float: left;
}
.queima-estoque-tag .fontello-icon-fire-station {
  color: #f0ad4e;
  padding-right: 5px;
}

.desconto-especial-tag {
  background-image: linear-gradient(90deg, #f5802f, #f15645);
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  float: left;
}
.desconto-especial-tag .fontello-icon-fire-station {
  color: #fff;
  padding-right: 5px;
}

.oferta-especial-full {
  display: block;
  width: 100%;
  background-color: #C00;
  padding: 3px 6px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.oferta-especial-full .fontello-icon-fire-station {
  color: #f0ad4e;
  padding-right: 5px;
}

.queima-estoque-full {
  display: block;
  width: 100%;
  background-color: #017fa3;
  padding: 3px 6px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.queima-estoque-full .fontello-icon-fire-station {
  color: #f0ad4e;
  padding-right: 5px;
}

.desconto-especial-full {
  display: block;
  width: 100%;
  background-image: linear-gradient(90deg, #f5802f, #f15645);
  padding: 3px 6px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.desconto-especial-full .fontello-icon-fire-station {
  color: #fff;
  padding-right: 5px;
}

.produto-original {
  display: block;
  width: 100%;
  padding: 5px 5px 0 5px;
  border-radius: 4px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  overflow: hidden;
  text-align: left;
  margin-bottom: 10px;
}
.produto-original a {
  float: left;
}
.produto-original .produto-original-text {
  padding-top: 7px;
  margin-left: 65px;
  line-height: 17px;
}
.produto-original .revendedor-autorizado {
  display: block;
  overflow: hidden;
  font-size: 11px;
  margin-top: 5px;
  padding: 5px;
  border-top: solid 1px #ccc;
}

/* PRODUTOS FORA DE LINHA */
.tooglelink {
  cursor: pointer;
  display: block;
}
.tooglelink i {
  font-size: 10px;
}
@media (min-width: 760px) {
  .tooglelink {
    display: inline-block;
  }
}

.aceita-bndes {
  position: absolute;
  right: 20px;
  top: 20px;
}

/* usado na listagem dos produtos nos resultados */
.caixa_produto_substituto {
  border-top: 1px solid #ccc;
  padding-top: 8px;
  margin-top: 8px;
  font-size: 11px;
  margin-bottom: 4px;
}

/* ==========================================================================
  CATEGORIAS
  ========================================================================== */
#categorias a {
  display: block;
}
#categorias i {
  padding-right: 10px;
}
#categorias h3 {
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 10px;
}
#categorias ul {
  list-style: none;
}
#categorias ul li {
  line-height: 28px;
}
#categorias h4 {
  background-color: #f2f2f2;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #666;
  padding: 6px 12px;
}

.categorias-submenu {
  list-style: none;
  padding: 0;
}
.categorias-submenu li {
  border-bottom: dotted 1px #cccccc;
  font-size: 10px;
  color: #cccccc;
}
.categorias-submenu li:hover {
  background-color: #f2f2f2;
}
.categorias-submenu li a {
  display: inline-block;
  padding: 7px 0;
  font-size: 14px;
}
@media (min-width: 640px) {
  .categorias-submenu li {
    float: left;
    width: 50%;
    list-style: decimal;
    list-style-position: inside;
  }
}
@media (min-width: 1024px) {
  .categorias-submenu li {
    width: 33%;
  }
}
@media (min-width: 1500px) {
  .categorias-submenu li {
    width: 25%;
  }
}
@media (min-width: 640px) {
  .categorias-submenu {
    overflow: hidden;
    margin-bottom: 40px;
  }
}

.categoria-last {
  margin-top: 10px;
}

.categoria-ver-todos {
  border-top: dotted 1px #ccc;
  padding-top: 5px;
  margin-top: 5px;
}

/* ==========================================================================
  BOX INDEX CATEGORIA
  ========================================================================== */
.box-categoria {
  box-shadow: none;
  text-align: left;
}
.box-categoria .box-categoria-link {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.box-categoria .box-categoria-link i {
  float: left;
  margin-right: 10px;
}
@media (min-width: 640px) {
  .box-categoria {
    text-align: center;
  }
  .box-categoria .box-categoria-link {
    font-weight: 400;
    color: #fff;
  }
  .box-categoria .box-categoria-link i {
    font-size: 34px;
    float: none;
    margin: 0;
    display: block;
  }
  .box-categoria .box-categoria-link i:before {
    margin-right: 0;
  }
  .box-categoria .box-categoria-link:hover {
    color: #FA5B25;
    text-decoration: none;
  }
}
@media (min-width: 1200px) {
  .box-categoria .box-categoria-link {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
  }
  .box-categoria .box-categoria-link i {
    font-size: 36px;
    display: block;
  }
  .box-categoria .box-categoria-link:hover {
    color: #FA5B25;
    text-decoration: none;
  }
}

.box-categoria-bg-leitores, .box-categoria-bg-impressoras, .box-categoria-bg-coletor, .box-categoria-bg-automacao, .box-categoria-bg-etiquetas, .box-categoria-bg-ribbons, .box-categoria-bg-informatica, .box-categoria-bg-pecas-e-acessorios {
  background-color: #666;
  margin-bottom: 10px;
  padding: 15px;
}

.box-categoria-bg-leitores {
  background: url("../img/categorias/leitores.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-impressoras {
  background: url("../img/categorias/impressoras.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-coletor {
  background: url("../img/categorias/coletor-de-dados.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-automacao {
  background: url("../img/categorias/automacao-comercial.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-etiquetas {
  background: url("../img/categorias/etiquetas.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-ribbons {
  background: url("../img/categorias/ribbons.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-informatica {
  background: url("../img/categorias/informatica.jpg") 0 0 no-repeat;
  background-size: cover;
}

.box-categoria-bg-pecas-e-acessorios {
  background: url("../img/categorias/pecas-e-acessorios.jpg") 0 0 no-repeat;
  background-size: cover;
}

@media (min-width: 880px) {
  .box-categoria-bg-leitores {
    background: url("../img/categorias/leitores.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-impressoras {
    background: url("../img/categorias/impressoras.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-coletor {
    background: url("../img/categorias/coletor-de-dados.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-automacao {
    background: url("../img/categorias/automacao-comercial.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-etiquetas {
    background: url("../img/categorias/etiquetas.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-ribbons {
    background: url("../img/categorias/ribbons.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-informatica {
    background: url("../img/categorias/informatica.jpg") 0 0 no-repeat;
    background-size: 100%;
  }

  .box-categoria-bg-pecas-e-acessorios {
    background: url("../img/categorias/pecas-e-acessorios.jpg") 0 0 no-repeat;
    background-size: 100%;
  }
}
/* ==========================================================================
  CATEGORIA MASONRY
  ========================================================================== */
.categoria-masonry {
  column-count: auto;
}
@media (min-width: 640px) {
  .categoria-masonry {
    display: block;
    column-count: 2;
    column-gap: 20px;
  }
}
@media (min-width: 1024px) {
  .categoria-masonry {
    column-count: 3;
  }
}
.categoria-masonry .leftbar__subtitulo {
  margin: 10px -10px;
}
.categoria-masonry ul {
  list-style: none;
  padding: 0 0 0 10px;
  margin-bottom: 0;
}
.categoria-masonry ul li {
  line-height: 22px;
  font-size: 13px;
}
.categoria-masonry a {
  color: #404040;
}

.categoria-masonry-item {
  display: inline-block;
  width: 100%;
}

/* ==========================================================================
  FABRICANTES
  ========================================================================== */
a.list-fabricante-nome {
  color: #404040;
  font-weight: 700;
  font-size: 16px;
}

.list-fabricante-telefone {
  font-size: 12px;
}
@media (min-width: 480px) {
  .list-fabricante-telefone {
    margin-bottom: 10px;
  }
}

.list-fabricante-vertodos {
  font-size: 12px;
}

.list-fabricante-suporte {
  display: inline-block;
  font-size: 11px;
  padding: 2px 10px;
  border: 1px dotted #ccc;
  background-color: #fff;
}
@media (min-width: 480px) {
  .list-fabricante-suporte {
    margin-top: 10px;
  }
}

.header-fabricante-single {
  overflow: hidden;
  border-bottom: solid 1px #cccccc;
}
.header-fabricante-single .logo-barra {
  display: block;
  width: 120px;
  margin: 0 auto;
}
@media (min-width: 480px) {
  .header-fabricante-single .logo-barra {
    float: left;
  }
}
.header-fabricante-single .titulo-barra {
  padding-bottom: 20px;
  text-align: center;
}
.header-fabricante-single .titulo-barra span:first-child {
  font-weight: 700;
  font-size: 16px;
  display: block;
}
@media (min-width: 480px) {
  .header-fabricante-single .titulo-barra {
    float: right;
    padding-top: 30px;
    text-align: right;
  }
}
.header-fabricante-single .barra-top {
  height: 20px;
}
.header-fabricante-single .barra-bottom {
  clear: both;
  height: 1px;
  background-color: #cccccc;
}

/* ==========================================================================
  DOWNLOADS
  ========================================================================== */
.container-download {
  min-height: 800px;
}

.busca-downloads {
  margin-bottom: 10px;
}
@media (min-width: 640px) {
  .busca-downloads {
    width: 80%;
  }
}
@media (min-width: 760px) {
  .busca-downloads {
    width: 70%;
  }
}
@media (min-width: 880px) {
  .busca-downloads {
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .busca-downloads {
    width: 40%;
  }
}

.downloads .num-downloads {
  float: right;
  font-size: 11px;
  color: #666;
  margin-top: 2px;
  margin-left: 10px;
}

.downloads-single .logo-fabricante-download {
  display: none;
}
@media (min-width: 640px) {
  .downloads-single .logo-fabricante-download {
    float: right;
    display: block;
    width: 120px;
    margin-top: -15px;
    margin-left: 30px;
  }
}
.downloads-single h3 {
  border-bottom: 1px dotted #ccc;
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  color: #404040;
  padding-bottom: 3px;
}
.downloads-single .produto-relacionado-titulo {
  font-size: 16px;
  font-weight: 700;
}
.downloads-single .info-downloads {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}
.downloads-single .btn-download {
  float: right;
  font-weight: 700;
  position: relative;
}
.downloads-single .box-download {
  margin-top: 30px;
  padding: 20px;
  border-color: #428bca;
  box-shadow: #3071a9 0 2px 0;
}
.downloads-single .box-download-produto-preco {
  padding: 6px 12px;
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-left: 30px;
  float: right;
  text-align: center;
  color: #2C9A00;
  box-shadow: #bababa 0 2px 0;
  margin-bottom: 15px;
}
.downloads-single .box-download-produto-valor {
  font-size: 18px;
  font-weight: 700;
}
.downloads-single .produtos-relacionados-download a {
  font-size: 14px;
}
.downloads-single .produtos-relacionados-grupo {
  overflow: hidden;
  margin-bottom: 25px;
}
.downloads-single .produtos-relacionados-grupo .produto-relacionado-imagem {
  float: left;
  margin-right: 15px;
}

.hide-small-downloads {
  display: none;
}
@media (min-width: 760px) {
  .hide-small-downloads {
    display: block;
  }
}

.download-contagem-regressiva {
  font-size: 60px;
  width: 150px;
  border: solid 1px #ccc;
  margin: 15px auto;
  border-radius: 4px;
  padding: 15px;
}

/* ==========================================================================
  DOWNLOAD TOP 10
  ========================================================================== */
.download-top-10 {
  font-size: 12px;
  line-height: 20px;
}
.download-top-10 h3 {
  font-size: 12px;
}
.download-top-10 a {
  color: #404040;
}
.download-top-10 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ==========================================================================
  BLOG
  ========================================================================== */
.grid-blog img {
  width: 100%;
  border: solid 1px #cccccc;
  height: auto;
}
.grid-blog .tit-blog {
  display: block;
  font-weight: 700;
  font-size: 16px;
}
.grid-blog .categoria-blog {
  font-size: 12px;
  color: #666;
  display: block;
  margin-bottom: 10px;
}
.grid-blog .post-meta {
  font-size: 12px;
  color: #666;
  display: block;
  margin-bottom: 10px;
}

.blog-single {
  width: 100%;
}
@media (min-width: 880px) {
  .blog-single {
    width: 75%;
    margin: 0 auto;
  }
  .blog-single h1 {
    font-size: 30px;
    line-height: 38px;
  }
}
.blog-single iframe {
  width: 100%;
}
@media (min-width: 880px) {
  .blog-single iframe {
    max-width: 640px;
  }
}
.blog-single .thumb-blog-single {
  margin-bottom: 20px;
}
@media (min-width: 880px) {
  .blog-single .thumb-blog-single {
    float: right;
    border-left: solid 40px #fff;
    border-bottom: solid 20px #fff;
  }
}
.blog-single .thumb-blog-single img {
  max-width: 375px;
  width: 100%;
  border: solid 1px #cccccc;
  height: auto;
}
.blog-single .post-meta {
  font-size: 12px;
  color: #666;
  display: block;
  margin-top: -10px;
  margin-bottom: 20px;
}
.blog-single .post-meta .post-autor {
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 760px) {
  .blog-single .post-meta .post-autor {
    display: inline-block;
    float: right;
    margin-bottom: 0;
  }
}
.blog-single h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 28px;
  clear: left;
  font-weight: 400;
  line-height: 34px;
}
.blog-single h3 {
  color: #404040;
  font-size: 23px;
  clear: left;
  font-weight: 400;
  margin-top: 20px;
}
.blog-single h4 {
  color: #404040;
  font-size: 18px;
  clear: left;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 5px;
}
.blog-single p {
  font-size: 16px;
  line-height: 1.64;
  margin-bottom: 20px;
  font-weight: 400;
}
.blog-single blockquote p {
  margin-bottom: 0;
}
.blog-single p + ul {
  margin-top: -20px;
}
.blog-single ul {
  margin-bottom: 30px;
}
.blog-single li {
  font-size: 16px;
  line-height: 28px;
}
.blog-single .center {
  text-align: center;
}
.blog-single figure {
  border: solid 1px #ccc;
  background-color: #fff;
  margin: 0 0 20px 0;
  text-align: center;
  display: inline-block;
  padding: 15px 20px 0 20px;
}
.blog-single figure figcaption {
  padding: 5px;
  text-align: center;
  font-size: 12px;
  background-color: #f2f2f2;
  margin: 15px -20px 0 -20px;
}
.blog-single figure.center {
  margin: 20px auto;
}
.blog-single img.right, .blog-single figure.right {
  margin: 20px 0 20px 40px;
}
.blog-single img.left, .blog-single figure.left {
  margin: 0 20px 10px 0;
}
.blog-single tbody > tr, .blog-single tbody > tr > td {
  border: none;
}
.blog-single tbody > tr:hover {
  background: #fff;
}
.blog-single table p:has(img) {
  margin-bottom: 0;
}
@media (min-device-width: 480px) and (max-device-width: 560px), only screen and (max-width: 560px) {
  .blog-single td {
    padding: 6px;
  }
}

/* ==========================================================================
                       GERADOR DE CÓDIGO DE BARRAS
  ========================================================================== */
.gerador-de-codigo-de-barras .gerador-visualizador-imagem-codigo {
  text-align: center;
  margin: 0px;
}
.gerador-de-codigo-de-barras .gerador-campos-full {
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 80%;
  height: 30px;
  margin: 0px 0px 2% 2%;
}
.gerador-de-codigo-de-barras .gerador-campos {
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 20%;
  height: 30px;
  margin: 0px 0px 2% 2%;
}
.gerador-de-codigo-de-barras .gerador-leftbar-itens {
  font-size: 14px;
}
.gerador-de-codigo-de-barras .gerador-texto-campos {
  font-size: 15px;
  font-weight: 700;
}
@media (min-width: 880px) {
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem-codigo {
    margin: 19% 0px;
  }
  .gerador-de-codigo-de-barras .gerador-campos-full {
    width: 80%;
  }
  .gerador-de-codigo-de-barras .gerador-campos {
    width: 25%;
  }
  .gerador-de-codigo-de-barras .gerador-de-codigo-de-barras-box {
    height: 335px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-codigo {
    height: 335px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem {
    width: 150px;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem-codigo {
    margin: 40px 0px;
  }
  .gerador-de-codigo-de-barras .gerador-de-codigo-de-barras-box {
    min-height: 338px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-codigo {
    min-height: 338px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem {
    width: 180px;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem-codigo {
    margin: 45px 0px;
  }
  .gerador-de-codigo-de-barras .gerador-de-codigo-de-barras-box {
    min-height: 320px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-codigo {
    min-height: 320px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem {
    width: 200px;
    height: auto;
  }
}
@media (min-width: 1600px) {
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem-codigo {
    margin: 11% 0px;
  }
  .gerador-de-codigo-de-barras .gerador-campos-full {
    width: 90%;
  }
  .gerador-de-codigo-de-barras .gerador-campos {
    width: 10%;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-codigo {
    min-height: 328px;
  }
  .gerador-de-codigo-de-barras .gerador-visualizador-imagem {
    width: 250px;
    height: auto;
  }
  .gerador-de-codigo-de-barras .gerador-leftbar-itens {
    font-size: 13px;
  }
}

/* ==========================================================================
GUIAS
========================================================================== */
.guia-banner {
  width: 100%;
}

.guia-imagem {
  display: none;
  text-align: center;
}
@media (min-width: 880px) {
  .guia-imagem {
    display: block;
  }
  .guia-imagem img {
    max-width: 1270px;
    max-height: 300px;
  }
}

@media (min-width: 880px) {
  .guia-single {
    width: 80%;
    background-color: white;
    margin: -76px auto 50px auto;
    z-index: 500;
    display: block;
    padding: 30px;
    position: relative;
  }
  .guia-single .panel-group {
    width: 60%;
  }
}
.guia-single table caption {
  font-size: 14px;
  padding: 10px;
  background-color: #f2f2f2;
  font-weight: 700;
  margin-bottom: 10px;
}
.guia-single table th {
  font-size: 14px;
  font-weight: 400;
}
.guia-single h3.top {
  font-size: 13px;
  font-weight: 700;
  color: #404040;
}
.guia-single .pontos-fortes-e-fracos {
  overflow: hidden;
}
.guia-single .pontos-fortes-e-fracos ul {
  padding-left: 30px;
  margin-bottom: 0;
}
.guia-single .pontos-fortes-e-fracos ul > li {
  font-size: 13px;
  line-height: 22px;
}

.guia-logo {
  display: block;
  text-align: center;
  font-size: 13px;
}
.guia-logo img {
  width: 120px;
  margin: 0 auto;
}

.guia-produto {
  display: block;
  text-align: center;
  font-size: 13px;
}
.guia-produto img {
  width: 150px;
  margin: 0 auto;
}

.summary ol {
  font-size: 13px;
  padding-left: 10px;
  margin-bottom: 0;
  list-style-type: none;
  counter-reset: item;
}
.summary ol > li {
  counter-increment: item;
}
.summary ol > li:before {
  content: counters(item,".") ". ";
}
.summary p {
  font-size: 13px;
  line-height: 22px;
}
.summary li {
  font-size: 13px;
  line-height: 22px;
}

.tabela-classificacao-ip_img {
  display: none;
}

@media (min-width: 880px) {
  .tabela-classificacao-ip_img {
    display: block;
  }

  .tabela-classificacao-ip_link {
    display: none;
  }
}
.guia-tabela-sub-titulo {
  background: #ccc;
  color: black;
}

.guia-tabela-uso {
  padding-bottom: 30px;
}

/* ==========================================================================
 INDEX GUIAS
  ========================================================================== */
.guia-index-img {
  max-width: 200px;
  margin: 0 auto 15px auto;
}
@media (min-width: 480px) {
  .guia-index-img {
    float: left;
    margin: 0;
    max-width: 150px;
  }
}
@media (min-width: 760px) {
  .guia-index-img {
    max-width: 200px;
  }
}

.guia-index-conteudo h2 {
  margin-bottom: 5px;
}
@media (min-width: 480px) {
  .guia-index-conteudo {
    overflow: hidden;
    display: block;
    margin-left: 170px;
  }
}
@media (min-width: 760px) {
  .guia-index-conteudo {
    margin-left: 220px;
  }
  .guia-index-conteudo p {
    font-size: 16px;
  }
}
.guia-index-conteudo .share {
  overflow: hidden;
  margin: 15px 0;
}

/* ==========================================================================
  COMPARATIVOS
  ========================================================================== */
.grid-comparativos {
  width: 100%;
  position: relative;
}
.grid-comparativos:nth-child(n) {
  margin-bottom: 7%;
  margin-right: 7%;
}
.grid-comparativos:nth-child(1n) {
  margin-right: 0;
  margin-bottom: 7%;
}
.grid-comparativos img {
  width: 100%;
}
.grid-comparativos .tit-comparativos {
  margin-bottom: 10px;
  display: block;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 480px) {
  .grid-comparativos {
    width: 49%;
  }
  .grid-comparativos:nth-child(n) {
    margin-bottom: 2%;
    margin-right: 2%;
  }
  .grid-comparativos:nth-child(2n) {
    margin-right: 0;
    margin-bottom: 2%;
  }
}
@media (min-width: 880px) {
  .grid-comparativos {
    width: 32%;
  }
  .grid-comparativos:nth-child(n) {
    margin-bottom: 2%;
    margin-right: 2%;
  }
  .grid-comparativos:nth-child(3n) {
    margin-right: 0;
    margin-bottom: 2%;
  }
}
@media (min-width: 1600px) {
  .grid-comparativos {
    width: 23.5%;
  }
  .grid-comparativos:nth-child(n) {
    margin-bottom: 2%;
    margin-right: 2%;
  }
  .grid-comparativos:nth-child(4n) {
    margin-right: 0;
    margin-bottom: 2%;
  }
}

.comparativos-single table > tbody > tr:last-child {
  background-color: #f2f2f2;
  font-weight: 700;
  font-size: 16px;
}
.comparativos-single table > tbody > tr:last-child td {
  height: 40px;
  border-bottom: solid 1px #cccccc;
}
.comparativos-single .comparativos-imagens {
  display: none;
}
@media (min-width: 760px) {
  .comparativos-single .comparativos-imagens {
    display: table-row;
  }
}

/* ==========================================================================
  AVALIAÇÕES E COMENTÁRIOS
  ========================================================================== */
.avaliacao {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin: 20px 0;
  padding: 15px;
  box-shadow: #bababa 0 2px 0;
}
.avaliacao p, .avaliacao h3 {
  margin: 0;
}
.avaliacao textarea {
  width: 100%;
}
.avaliacao .nota {
  margin-bottom: 15px;
}
.avaliacao .data {
  font-size: 11px;
  color: #666;
  margin-bottom: 5px;
}
.avaliacao > .avaliacao {
  margin-top: 20px;
  margin-bottom: 0;
  overflow: hidden;
}
.avaliacao .btn-success {
  margin-bottom: 20px;
}
@media (min-width: 480px) {
  .avaliacao .btn-success {
    float: left;
    margin-bottom: 0;
  }
}
.avaliacao .btn-default {
  display: block;
}
@media (min-width: 480px) {
  .avaliacao .btn-default {
    float: right;
  }
}

#deixe-sua-avaliacao {
  margin-top: 50px;
  overflow: hidden;
}
#deixe-sua-avaliacao textarea {
  width: 100%;
}
@media (min-width: 480px) {
  #deixe-sua-avaliacao .btn-success {
    float: left;
  }
}

.comentario-link-responder {
  display: block;
  clear: both;
  overflow: hidden;
}

.textarea_resposta {
  width: 70%;
  margin-top: 7px;
}

.box-comentario-resposta {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-top: 5px;
  padding: 7px 9px;
}

/* ==========================================================================
  LOGIN
  ========================================================================== */
.cadastro-login .cadastro .radio {
  margin-bottom: 10px;
  cursor: pointer;
}
.cadastro-login .cadastro .radio label {
  cursor: pointer;
}
@media (min-width: 760px) {
  .cadastro-login .cadastro {
    float: left;
    width: 50%;
    padding-right: 30px;
  }
}
@media (min-width: 760px) {
  .cadastro-login .login {
    float: right;
    width: 50%;
  }
}
.cadastro-login .esqueci-senha {
  display: block;
  font-size: 12px;
}

@media (min-width: 760px) {
  .recuperar-senha {
    width: 70%;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .recuperar-senha {
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .recuperar-senha {
    width: 50%;
  }
}

@media (min-width: 480px) {
  .novasenha {
    float: right;
  }
}

@media (min-width: 480px) {
  .lembrei-a-senha {
    float: left;
  }
}

/* ==========================================================================
  ENDEREÇOS
  ========================================================================== */
.grid-checkout {
  width: 100%;
}
.grid-checkout:nth-child(n) {
  margin-bottom: 7%;
  margin-right: 7%;
}
.grid-checkout:nth-child(1n) {
  margin-right: 0;
  margin-bottom: 7%;
}
.grid-checkout h2 {
  margin-top: 0;
}
.grid-checkout .colunas {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .grid-checkout {
    width: 48.5%;
    min-height: 250px;
  }
  .grid-checkout:nth-child(n) {
    margin-bottom: 3%;
    margin-right: 3%;
  }
  .grid-checkout:nth-child(2n) {
    margin-right: 0;
    margin-bottom: 3%;
  }
}
@media (min-width: 880px) {
  .grid-checkout {
    width: 32%;
  }
  .grid-checkout:nth-child(n) {
    margin-bottom: 2%;
    margin-right: 2%;
  }
  .grid-checkout:nth-child(3n) {
    margin-right: 0;
    margin-bottom: 2%;
  }
}

.isento-ie {
  padding-top: 10px;
  float: left;
}
.isento-ie input {
  display: inline-block;
  width: auto;
  margin-top: 4px;
  margin-right: 8px;
}
@media (min-width: 880px) {
  .isento-ie {
    margin-left: 30px;
    padding-top: 6px;
  }
}

.tipo-pessoa a.btn-pessoa-juridica {
  margin: 3px 0 10px 0;
}
@media (min-width: 640px) {
  .tipo-pessoa a.btn-pessoa-juridica {
    margin: 0 10px 0 10px;
  }
}

.resumo-pedido-header {
  display: none;
}
@media (min-width: 880px) {
  .resumo-pedido-header {
    display: block;
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    border: solid 1px #cccccc;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 10px;
    margin-bottom: 15px;
  }
}

/* ==========================================================================
  CONTATO
  ========================================================================== */
#contact-form {
  margin-bottom: 20px;
}

.captcha {
  margin-top: 10px;
  clear: both;
}
@media (min-width: 880px) {
  .captcha {
    padding-top: 10px;
    overflow: hidden;
  }
}

@media (min-width: 880px) {
  .captcha #yw0 {
    margin-left: 17%;
    float: left;
  }
}
.captcha #yw0_button {
  font-size: 11px;
  margin-top: 12px;
  float: right;
}
@media (min-width: 880px) {
  .captcha #yw0_button {
    float: left;
  }
}

.telefones-mobile {
  display: flex;
  flex-wrap: wrap;
  border-top: dotted 1px #cccccc;
  list-style: none;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
}
.telefones-mobile li {
  flex-basis: 100%;
  border-bottom: dotted 1px #cccccc;
  padding: 5px 0;
}
@media (min-width: 390px) {
  .telefones-mobile li {
    flex-basis: 50%;
  }
}
@media (min-width: 520px) {
  .telefones-mobile li {
    flex-basis: 33.33%;
  }
}
@media (min-width: 1024px) {
  .telefones-mobile {
    display: none;
  }
}

.telefones {
  display: none;
}
@media (min-width: 1024px) {
  .telefones {
    display: flex;
    flex-wrap: wrap;
    border-top: dotted 1px #cccccc;
    list-style: none;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
  }
  .telefones li {
    flex-basis: 33.33%;
    border-bottom: dotted 1px #cccccc;
    padding: 5px 0;
  }
}
@media (min-width: 1024px) and (min-width: 1600px) {
  .telefones li {
    flex-basis: 25%;
  }
}

.compromisso-qualidade-interno-banner {
  padding: 11px 16px;
  background-color: #ecefdf;
  border-radius: 4px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  color: #2C9A00;
  border: solid 1px #d8debd;
}

.compromisso-qualidade-interno-banner-link {
  padding: 11px 16px;
  background-color: #357ebd;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  border: solid 1px #2a6395;
}
.compromisso-qualidade-interno-banner-link a {
  color: #fff;
}

/* ==========================================================================
  ERRO 404
  ========================================================================== */
.error404 {
  padding: 20px;
  background-color: #f2f2f2;
  border: solid 1px #cccccc;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 60px;
  color: #666;
}

@media (min-width: 880px) {
  .error-busca {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .error-busca {
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .error-busca {
    width: 40%;
  }
}

/* ==========================================================================
  FORA DE LINHA
  ========================================================================== */
.produto-fora-de-linha {
  padding: 5px 10px;
  background-color: #f2dede;
  border: solid  1px #ebccd1;
  border-radius: 4px;
  margin-bottom: 15px;
}
.produto-fora-de-linha span.titulo {
  font-size: 28px;
  color: #a94442;
  font-weight: 700;
  display: block;
}
.produto-fora-de-linha span.descricao {
  font-size: 14px;
  display: block;
  margin-bottom: 0;
  line-height: 20px;
}
@media (min-width: 880px) {
  .produto-fora-de-linha {
    padding: 10px 15px;
  }
  .produto-fora-de-linha span.titulo {
    font-size: 40px;
  }
}

.produto-substituto span.titulo {
  font-size: 24px;
  font-weight: 700;
  display: block;
  margin-top: 20px;
}
.produto-substituto span.descricao {
  display: block;
}
.produto-substituto a.link-substituto {
  display: block;
  font-size: 16px;
  padding: 15px 0;
}

/* ==========================================================================
  PRODUTO INDISPONIVEL
  ========================================================================== */
.produto-indisponivel {
  padding: 20px;
  background-color: #fcf8e3;
  border: solid  1px #f0ad4e;
  border-radius: 4px;
}
.produto-indisponivel span.titulo {
  font-size: 40px;
  color: #f0ad4e;
  font-weight: 700;
  display: block;
}
.produto-indisponivel span.descricao {
  font-size: 14px;
  display: block;
  margin-bottom: 0;
  line-height: 20px;
}

/* ==========================================================================
  SOB CONSULTA
  ========================================================================== */
.sob-consulta .box-produto-sob-consulta {
  padding: 15px 15px 10px 15px;
  background-color: #dff0d8;
  border: solid  1px #bfd8ab;
  border-radius: 4px;
  margin-bottom: 15px;
}
.sob-consulta .box-produto-sob-consulta span.titulo {
  font-size: 40px;
  color: #3c763d;
  font-weight: 700;
  display: block;
}
.sob-consulta .box-produto-sob-consulta span.descricao {
  font-size: 14px;
  display: block;
  margin-bottom: 0;
  line-height: 20px;
}
.sob-consulta .box-tenho-interesse {
  margin-bottom: 15px;
}
.sob-consulta .box-tenho-interesse .btn {
  font-size: 16px;
  padding: 8px 50px;
}

.box-tenho-interesse-list {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 400;
}

.btn-solicitar-orcamento {
  padding: 6px 12px 4px 12px;
}

/* ==========================================================================
  DEPOIMENTOS
  ========================================================================== */
.grid-depoimento {
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.grid-depoimento span {
  font-size: 11px;
}
.grid-depoimento span .fontello-icon-thumbs-up {
  font-size: 18px;
  color: #2C9A00;
}
.grid-depoimento span .fontello-icon-minus-circle {
  font-size: 12px;
  color: #f0ad4e;
}
.grid-depoimento .depoimento-texto {
  margin: 3px 0 0 0;
}

#pagination {
  text-align: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  font-size: 12px;
  color: #666;
}

.listitempage h2 {
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 10px 15px;
}

.listitempage .voltar-ao-topo {
  margin-bottom: 15px;
  float: right;
}

.grid-depoimento-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.grid-depoimento-column {
  width: 100%;
}

@media (min-width: 1200px) {
  .listitempage .voltar-ao-topo {
    position: absolute;
    right: 9px;
    top: 9px;
    margin-bottom: 0;
    float: none;
  }

  .grid-depoimento-column {
    width: 48%;
  }
}
/* ==========================================================================
  PARCERIAS PREMIADAS
  ========================================================================== */
.parcerias-premiadas {
  overflow: hidden;
  display: block;
  margin-bottom: 30px;
}
.parcerias-premiadas img.logo-parcerias-premiadas {
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 880px) {
  .parcerias-premiadas img.logo-parcerias-premiadas {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

/* ==========================================================================
  BUSCA SPIN
  ========================================================================== */
.spinner {
  display: none;
  position: fixed;
  top: 30%;
  left: 50%;
  margin-left: -50px;
  /* half width of the spinner gif */
  margin-top: -50px;
  /* half height of the spinner gif */
  text-align: center;
  z-index: 1234;
  overflow: auto;
  width: 150px;
  /* width of the spinner gif */
  height: 302px;
  /*hight of the spinner gif +2px to fix IE8 issue */
}

.nao-ativo {
  pointer-events: none;
  cursor: default;
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}

.site-seguro-rightbar {
  display: none;
}
@media (min-width: 1024px) {
  .site-seguro-rightbar {
    display: block;
    padding: 5px 10px;
    background-color: #47a447;
    border: solid 1px #3f923f;
    color: #fff;
    border-radius: 4px;
    display: block;
    margin-bottom: 15px;
  }
  .site-seguro-rightbar .icone-img-site-seguro {
    display: inline-block;
    margin-right: 8px;
    float: left;
  }
  .site-seguro-rightbar:hover {
    text-decoration: none;
    background-color: #449d44;
    color: #fff;
  }
  .site-seguro-rightbar .fontello-icon-ok-circle {
    font-size: 10px;
    float: right;
    margin-top: 2px;
  }
}

.site-seguro-banner {
  padding: 15px;
  background-color: #47a447;
  border: solid 1px #3f923f;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 34px;
  color: #fff;
}

/* ==========================================================================
  IMPORTS 2
  ========================================================================== */
/* ==========================================================================
  CHECKOUT
  ========================================================================== */
#header-checkout {
  background-color: #1b1811;
  padding: 15px 0 10px 0;
}
#header-checkout .site-seguro-checkout {
  float: right;
  display: block;
  width: 132px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #4a422f;
  color: #f2f2f2;
  font-weight: 700;
  padding: 7px 0 5px 12px;
  border: solid 1px #796b4c;
}
#header-checkout .site-seguro-checkout .icone-img-site-seguro {
  display: inline-block;
  margin-right: 10px;
  float: right;
}
#header-checkout .voltar-carrinho {
  float: right;
  margin-top: 3px;
  font-size: 11px;
  border-radius: 4px;
  background-color: #4a422f;
  color: #f2f2f2;
  padding: 3px 6px 3px 2px;
  border: solid 1px #796b4c;
}

.breadcrumb-checkout-pf {
  display: block;
  overflow: hidden;
  padding: 15px 0;
  border-bottom: solid 1px #cccccc;
  background-color: #f2f2f2;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas {
  list-style: none;
  padding: 0;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas li {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 40px;
  padding-top: 6px;
  min-height: 3em;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas li:before {
  content: attr(data-number);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  background: #FA5B25;
  z-index: 10;
  font-weight: 700;
  text-align: center;
  padding: 6px 12px;
  color: #fff;
}
@media (min-width: 640px) {
  .breadcrumb-checkout-pf ul.breadcrumb-etapas li {
    display: inline-block;
    width: 25%;
    /*reduzindo de 20% para 16%, pois agora poderá ter um item adicional chamado SERVIÇOS */
    float: left;
    padding: 0;
    text-align: center;
  }
  .breadcrumb-checkout-pf ul.breadcrumb-etapas li:before {
    width: 10px;
    margin: 0 auto 10px auto;
    position: relative;
    display: block;
  }
  .breadcrumb-checkout-pf ul.breadcrumb-etapas li:after {
    content: '';
    position: absolute;
    top: 14px;
    left: calc(50% + 1em);
    width: 100%;
    height: 3px;
    background: #FA5B25;
    z-index: 8;
  }
  .breadcrumb-checkout-pf ul.breadcrumb-etapas li:last-child:after {
    display: none;
  }
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas .active-checkout:before {
  background: #FA5B25;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas .active-checkout:after {
  background: #cccccc;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas .active-checkout ~ li:before {
  background: #cccccc;
}
.breadcrumb-checkout-pf ul.breadcrumb-etapas .active-checkout ~ li:after {
  background: #cccccc;
}

.breadcrumb-checkout-pj {
  display: block;
  overflow: hidden;
  padding: 15px 0;
  border-bottom: solid 1px #cccccc;
  background-color: #f2f2f2;
}
.breadcrumb-checkout-pj .selecione-uma-opcao {
  font-size: 20px;
  text-align: center;
}
@media (min-width: 640px) {
  .breadcrumb-checkout-pj {
    padding: 40px 0;
  }
  .breadcrumb-checkout-pj .selecione-uma-opcao {
    font-size: 24px;
  }
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas {
  list-style: none;
  padding: 0;
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas li {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 40px;
  padding-top: 6px;
  min-height: 3em;
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas li:before {
  content: attr(data-number);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  background: #FA5B25;
  z-index: 10;
  font-weight: 700;
  text-align: center;
  padding: 6px 12px;
  color: #fff;
}
@media (min-width: 640px) {
  .breadcrumb-checkout-pj ul.breadcrumb-etapas li {
    display: inline-block;
    width: 33%;
    /*reduzindo de 20% para 16%, pois agora poderá ter um item adicional chamado SERVIÇOS */
    float: left;
    padding: 0;
    text-align: center;
  }
  .breadcrumb-checkout-pj ul.breadcrumb-etapas li:before {
    width: 10px;
    margin: 0 auto 10px auto;
    position: relative;
    display: block;
  }
  .breadcrumb-checkout-pj ul.breadcrumb-etapas li:after {
    content: '';
    position: absolute;
    top: 14px;
    left: calc(50% + 1em);
    width: 100%;
    height: 3px;
    background: #FA5B25;
    z-index: 8;
  }
  .breadcrumb-checkout-pj ul.breadcrumb-etapas li:last-child:after {
    display: none;
  }
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas .active-checkout:before {
  background: #FA5B25;
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas .active-checkout:after {
  background: #cccccc;
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas .active-checkout ~ li:before {
  background: #cccccc;
}
.breadcrumb-checkout-pj ul.breadcrumb-etapas .active-checkout ~ li:after {
  background: #cccccc;
}

.ckecktout-tipo-faturamento .box {
  text-align: center;
}

.numero-pedido {
  font-size: 34px;
  color: #2C9A00;
  font-weight: bold;
}

#footer-checkout {
  clear: both;
  background-color: #f2f2f2;
  padding: 15px 0;
  text-align: center;
  font-size: 12px;
}

.checkout-numeroStep {
  font-weight: 700;
  color: #FA5B25;
}

.label-empresa-endereco {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

#dark {
  z-index: 40 !important;
}

#boxLight {
  z-index: 50 !important;
}

.container-checkout {
  min-height: 400px;
}
.container-checkout .nav-tabs h2 {
  margin: 5px 0;
  padding: 0;
  border: 0;
  font-size: 16px;
}
.container-checkout .nav-tabs li.r-tabs-tab {
  width: 22%;
  text-align: center;
}
.container-checkout .nav-tabs li.r-tabs-tab a {
  width: 100%;
}
.container-checkout .nav-tabs li.r-tabs-tab a i {
  font-size: 24px;
}
@media (min-width: 760px) {
  .container-checkout .nav-tabs li.r-tabs-tab a i {
    display: block;
  }
}
.container-checkout .r-tabs-tab h2, .container-checkout .r-tabs-accordion-title h2 {
  margin: 5px 0;
  padding: 0;
  border: 0;
  font-size: 16px;
}
.container-checkout .panel-group .panel + .panel {
  margin-top: 15px;
}
.container-checkout .opcoes-parcelamento {
  font-size: 14px;
  font-weight: 700;
}
.container-checkout .label-empresa-endereco2 {
  color: #666;
  font-weight: 400;
  font-size: 12px;
}
.container-checkout .r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0 6px;
  list-style: none;
}
.container-checkout .r-tabs {
  font-size: 14px;
}
.container-checkout .tab-pane h2 {
  margin-top: 0;
}

/* ==========================================================================
  CARRINHO
  ========================================================================== */
.carrinho .not-active {
  pointer-events: none;
  cursor: default;
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}
.carrinho .head-tabela {
  display: none;
}
@media (min-width: 880px) {
  .carrinho .head-tabela {
    display: block;
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    border: solid 1px #cccccc;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 10px;
    margin-bottom: 15px;
  }
  .carrinho .head-tabela span {
    float: left;
  }
  .carrinho .head-tabela span:nth-child(1) {
    width: 80px;
  }
  .carrinho .head-tabela span:nth-child(2) {
    width: 29%;
  }
  .carrinho .head-tabela span:nth-child(3) {
    width: 19%;
  }
  .carrinho .head-tabela span:nth-child(4) {
    width: 15%;
  }
  .carrinho .head-tabela span:nth-child(5) {
    width: 16%;
  }
  .carrinho .head-tabela span:nth-child(6) {
    width: 5%;
  }
}
@media (min-width: 1200px) {
  .carrinho .head-tabela span:nth-child(1) {
    width: 80px;
  }
  .carrinho .head-tabela span:nth-child(2) {
    width: 43%;
  }
  .carrinho .head-tabela span:nth-child(3) {
    width: 15%;
  }
  .carrinho .head-tabela span:nth-child(4) {
    width: 12%;
  }
  .carrinho .head-tabela span:nth-child(5) {
    width: 13%;
  }
  .carrinho .head-tabela span:nth-child(6) {
    width: 3%;
  }
}
.carrinho .carrinho-left {
  margin-bottom: 20px;
}
@media (min-width: 640px) {
  .carrinho .carrinho-left {
    float: left;
    width: 60%;
    margin-bottom: 0;
  }
}
@media (min-width: 760px) {
  .carrinho .carrinho-left {
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .carrinho .carrinho-left {
    width: 80%;
  }
}
@media (min-width: 640px) {
  .carrinho .carrinho-right {
    float: right;
    width: 40%;
    padding-left: 20px;
  }
}
@media (min-width: 760px) {
  .carrinho .carrinho-right {
    width: 30%;
  }
}
@media (min-width: 1400px) {
  .carrinho .carrinho-right {
    width: 20%;
  }
}
.carrinho .carrinho-produto {
  border: solid 1px #cccccc;
  padding: 10px;
  margin-bottom: 15px;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto {
    border: 0;
    padding: 0;
    width: 100%;
    border-bottom: solid 1px #cccccc;
    overflow: hidden;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}
.carrinho .carrinho-produto .coluna-imagem {
  float: left;
  margin-right: 10px;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-imagem {
    width: 80px;
    margin-right: 0;
  }
}
.carrinho .carrinho-produto .coluna-titulo .produto-titulo {
  font-weight: 700;
  line-height: 18px;
}
.carrinho .carrinho-produto .coluna-titulo a {
  display: block;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-titulo {
    float: left;
    width: 28%;
    margin-right: 2%;
  }
}
@media (min-width: 1200px) {
  .carrinho .carrinho-produto .coluna-titulo {
    width: 41%;
  }
}
.carrinho .carrinho-produto .coluna-qty {
  clear: both;
  border-top: dotted 1px #cccccc;
  padding-top: 10px;
  margin-top: 10px;
}
.carrinho .carrinho-produto .coluna-qty label {
  font-weight: 400;
  font-size: 12px;
}
.carrinho .carrinho-produto .coluna-qty .form-group {
  margin-bottom: 10px;
}
.carrinho .carrinho-produto .coluna-qty .form-group input {
  width: 60%;
}
.carrinho .carrinho-produto .coluna-qty .form-control {
  width: 60px;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-qty {
    clear: none;
    border: 0;
    margin: 0;
    padding: 0;
    float: left;
    width: 15%;
  }
  .carrinho .carrinho-produto .coluna-qty label {
    display: none;
  }
}
@media (min-width: 1200px) {
  .carrinho .carrinho-produto .coluna-qty {
    width: 12%;
  }
}
.carrinho .carrinho-produto .coluna-preco {
  font-size: 16px;
  font-weight: 700;
}
.carrinho .carrinho-produto .coluna-preco span {
  font-size: 12px;
  font-weight: 400;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-preco {
    float: left;
    width: 18%;
    font-size: 14px;
    font-weight: 400;
  }
  .carrinho .carrinho-produto .coluna-preco span {
    display: none;
  }
}
@media (min-width: 1200px) {
  .carrinho .carrinho-produto .coluna-preco {
    width: 15%;
  }
}
.carrinho .carrinho-produto .coluna-subtotal {
  font-size: 12px;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-subtotal {
    float: left;
    width: 16%;
    font-size: 14px;
  }
  .carrinho .carrinho-produto .coluna-subtotal span.subtotal-hide {
    display: none;
  }
}
@media (min-width: 1200px) {
  .carrinho .carrinho-produto .coluna-subtotal {
    width: 15%;
  }
}
.carrinho .carrinho-produto .coluna-remover {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-top: 10px;
}
.carrinho .carrinho-produto .coluna-remover a.remover-produto {
  font-size: 11px;
  color: #666;
  display: block;
  padding: 7px 10px;
}
.carrinho .carrinho-produto .coluna-remover a.remover-produto:hover {
  background-color: #f2f2f2;
}
@media (min-width: 880px) {
  .carrinho .carrinho-produto .coluna-remover {
    float: left;
    width: 5%;
    border: none;
    margin-top: 0;
  }
  .carrinho .carrinho-produto .coluna-remover a.remover-produto {
    margin-top: 0;
    padding: 0;
  }
  .carrinho .carrinho-produto .coluna-remover a.remover-produto:hover {
    background-color: #fff;
  }
  .carrinho .carrinho-produto .coluna-remover .fontello-icon-cancel-circle-2 {
    font-size: 16px;
  }
  .carrinho .carrinho-produto .coluna-remover span {
    display: none;
  }
}
@media (min-width: 1200px) {
  .carrinho .carrinho-produto .coluna-remover {
    width: 3%;
  }
}
.carrinho .nao-sabe-cep {
  font-size: 11px;
  margin-top: 5px;
}
.carrinho #calculo-frete-novo {
  overflow: hidden;
  margin-bottom: 5px;
}
.carrinho .btn-calcular-frete {
  float: left;
}
@media (min-width: 480px) {
  .carrinho .btn-calcular-frete {
    float: none;
  }
}
.carrinho .cep-inserido {
  font-size: 12px;
  display: block;
  background-color: #f5f9fc;
  padding: 10px 15px;
  margin: -15px -15px 20px -15px;
  border-bottom: solid 1px #e1edf7;
}
.carrinho .valor-frete {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: block;
  border-bottom: dotted 1px #cccccc;
}
.carrinho .valor-frete span {
  font-size: 16px;
  font-weight: 700;
}
.carrinho .valor-frete .ValorSedex, .carrinho .valor-frete .ValorPAC {
  display: inline-block;
}
.carrinho .valor-frete .PrazoSedex, .carrinho .valor-frete .PrazoPAC {
  font-size: 11px;
  font-weight: 400;
}
.carrinho .carrinho-subtotal {
  display: block;
  border-bottom: dotted 1px #cccccc;
  padding-bottom: 8px;
}
.carrinho .carrinho-frete {
  display: block;
  padding-bottom: 8px;
  padding-top: 8px;
}
.carrinho .carrinho-frete small {
  font-size: 10px;
  margin-top: 0;
}
.carrinho .carrinho-total {
  display: block;
  border-top: solid 1px #cccccc;
  background-color: #f2f2f2;
  margin: 0 -15px -10px -15px;
  padding: 10px 15px;
}
.carrinho .carrinho-total span {
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 880px) {
  .carrinho .btn-finalizar-pedido {
    padding: 10px;
    font-weight: 700;
  }
}
.carrinho .carrinho-solicitar-orcamento-desktop {
  display: none;
}
@media (min-width: 640px) {
  .carrinho .carrinho-solicitar-orcamento-desktop {
    display: block;
  }
}
.carrinho .carrinho-solicitar-orcamento-mobile {
  display: block;
}
@media (min-width: 640px) {
  .carrinho .carrinho-solicitar-orcamento-mobile {
    display: none;
  }
}

#footer {
  clear: both;
}
#footer .icone-whatsapp {
  position: absolute;
  left: 6px;
  top: 3px;
}
#footer .footer-marron {
  background-color: #1b1811;
  padding-top: 20px;
  color: #FFF;
}
#footer .footer-marron a {
  color: #FFF;
  text-decoration: underline;
}
#footer .footer-titulo {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
}
#footer .obs-precos-disponibilidade {
  border-top: dotted 1px #cccccc;
  padding: 10px 0;
  font-size: 11px;
}
#footer .entre-em-contato {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 15px;
  text-align: center;
}
#footer .entre-em-contato .entre-em-contato-botao {
  display: block;
}
@media (min-width: 760px) {
  #footer .entre-em-contato .entre-em-contato-botao {
    display: none;
  }
}
#footer .entre-em-contato .entre-em-contato-telefones {
  display: none;
}
@media (min-width: 760px) {
  #footer .entre-em-contato .entre-em-contato-telefones {
    display: block;
    font-size: 12px;
  }
  #footer .entre-em-contato .entre-em-contato-telefones ul {
    list-style: none;
    padding: 0 80px;
    margin-bottom: 0;
    line-height: 24px;
  }
  #footer .entre-em-contato .entre-em-contato-telefones ul li {
    display: inline-block;
  }
  #footer .entre-em-contato .entre-em-contato-telefones ul li i {
    padding-left: 6px;
  }
}
#footer .footer-busca {
  background: #1b1811;
  padding: 20px 0;
  border-bottom: 1px solid #4a422f;
}
@media (min-width: 880px) {
  #footer .footer-busca-form {
    width: 60%;
    margin: 0 auto;
  }
}
#footer .formas-e-redes-sociais {
  font-size: 12px;
}
@media (min-width: 760px) {
  #footer .formas-e-redes-sociais .footer-formas-pagamento {
    width: 45%;
    float: left;
    margin-right: 10%;
  }
}
#footer .formas-e-redes-sociais .footer-redes-sociais {
  margin-bottom: 20px;
  border-top: solid 1px #4a422f;
  padding-top: 20px;
}
@media (min-width: 760px) {
  #footer .formas-e-redes-sociais .footer-redes-sociais {
    width: 45%;
    float: left;
    border-top: none;
    padding-top: 0;
  }
}
#footer .formas-e-redes-sociais .footer-redes-sociais ul {
  list-style: none;
  padding: 0;
  clear: both;
  margin: 0;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li {
  display: inline-block;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li a {
  color: #695e42;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li a:hover {
  color: #796b4c;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-googleplus-rect {
  font-size: 30px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-facebook {
  font-size: 34px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-instagram-filled {
  font-size: 29px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-twitter {
  font-size: 34px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-youtube {
  font-size: 34px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-linkedin {
  font-size: 34px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-icones > li .fontello-icon-doc-1 {
  font-size: 32px;
}
#footer .formas-e-redes-sociais .footer-redes-sociais .redes-sociais-share > li {
  padding: 5px 0;
}
#footer .footer-menu-selos {
  overflow: hidden;
  margin-top: 20px;
}
#footer .footer-menu {
  border-bottom: solid 1px #cccccc;
  margin-bottom: 20px;
}
#footer .footer-menu ul {
  list-style: none;
  padding: 0;
  font-size: 12px;
  margin-bottom: 15px;
}
#footer .footer-menu ul li {
  display: inline-block;
  line-height: 24px;
}
#footer .footer-menu ul li a {
  color: #666;
}
@media (min-width: 760px) {
  #footer .footer-menu {
    width: 45%;
    float: left;
    margin-right: 10%;
    border: 0;
    margin-bottom: 0;
  }
}
#footer .footer-selos {
  padding-bottom: 20px;
}
@media (min-width: 760px) {
  #footer .footer-selos {
    width: 45%;
    float: left;
  }
}
#footer .copyright {
  background-color: #1b1811;
  padding: 10px 0;
  color: #f2f2f2;
  font-size: 11px;
  line-height: 16px;
}
#footer .copyright a {
  color: #f2f2f2;
}
#footer .copyright .coluna-esquerda {
  text-align: center;
}
@media (min-width: 880px) {
  #footer .copyright .coluna-esquerda {
    border-right: solid 1px #ccc;
    margin-right: 20px;
    text-align: right;
  }
}
#footer .copyright .coluna-direita {
  text-align: center;
}
@media (min-width: 880px) {
  #footer .copyright .coluna-direita {
    text-align: left;
  }
}
@media (min-width: 880px) {
  #footer .copyright .col-1-2 {
    width: 48%;
  }
}
#footer .footer-orcamento {
  text-align: center;
  border-top: dotted 1px #ccc;
  border-bottom: dotted 1px #ccc;
  padding: 10px 0;
  margin-bottom: 15px;
}

.logo-bcash {
  float: left;
  margin-right: 30px;
}

.gallery-container {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
}
.gallery-container .lista-pagamento {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
}

.pagination .first, .pagination .last, .pagination .next, .pagination .previous {
  display: none;
}

.paging_bootstrap {
  text-align: center;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px auto;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination .selected > a {
  background-color: #2C9A00;
  color: #fff;
}
.pagination .selected > a:hover {
  background-color: #2C9A00;
  color: #fff;
}

.box-minha-conta-top {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
  overflow: hidden;
}
.box-minha-conta-top h1 {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
}

a.box-minha-conta {
  border: solid 1px #cccccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
  display: grid;
  align-items: center;
  align-content: center;
  transition: transform 0.2s, box-shadow 0.2s;
  color: #404040;
  text-align: center;
}
a.box-minha-conta .box-icon {
  font-size: 28px;
  color: #FA5B25;
  text-align: center;
}
a.box-minha-conta:hover {
  box-shadow: #bababa 0 0 4px;
  text-decoration: none;
}
a.box-minha-conta p {
  margin-bottom: 5px;
}
a.box-minha-conta h2 {
  margin-top: 0;
}

.minhaconta-pedido [class^="fontello-icon-"], .minhaconta-orcamento [class^="fontello-icon-"] {
  font-size: 22px;
}
.minhaconta-pedido .label, .minhaconta-orcamento .label {
  font-size: 10px;
}
.minhaconta-pedido .label-default, .minhaconta-orcamento .label-default {
  color: #333;
  background-color: #fff;
  border: solid 1px #cccccc;
}
.minhaconta-pedido i.fontello-icon-right-circle-1, .minhaconta-orcamento i.fontello-icon-right-circle-1 {
  font-size: 10px;
  color: #428bca;
}

.minhaconta-pedido-single h1 .fontello-icon-left-circle-1, .minhaconta-orcamento-single h1 .fontello-icon-left-circle-1 {
  font-size: 21px;
}
.minhaconta-pedido-single .label, .minhaconta-orcamento-single .label {
  font-size: 13px;
  padding: 4px 8px;
}
.minhaconta-pedido-single .numero-pedido, .minhaconta-orcamento-single .numero-pedido {
  font-size: 26px;
}
.minhaconta-pedido-single .sub-itens, .minhaconta-pedido-single .carrinho-subtotal, .minhaconta-orcamento-single .sub-itens, .minhaconta-orcamento-single .carrinho-subtotal {
  margin-top: 8px;
}
.minhaconta-pedido-single h2.top, .minhaconta-orcamento-single h2.top {
  margin-top: 0;
}
.minhaconta-pedido-single .btn-icon, .minhaconta-orcamento-single .btn-icon {
  background-color: white;
  margin-left: 10px;
  border-color: #FA5B25;
  float: right;
  padding-bottom: 0;
  width: 40px;
  height: 32px;
}
.minhaconta-pedido-single .btn-icon img, .minhaconta-orcamento-single .btn-icon img {
  width: 20px;
  height: 20px;
}
.minhaconta-pedido-single .btn-icon i, .minhaconta-orcamento-single .btn-icon i {
  color: #FA5B25;
  font-size: 16px;
  margin-left: 0px;
  margin-right: -5px;
}
.minhaconta-pedido-single .flex-columns, .minhaconta-orcamento-single .flex-columns {
  display: flex;
  gap: 20px;
}
.minhaconta-pedido-single .flex-columns ul, .minhaconta-orcamento-single .flex-columns ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card-info-single {
  background-color: #FAFAFA;
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 10px 15px;
}
.card-info-single h2 {
  margin: 0px 0 10px;
}

.carrinho-total {
  font-size: 20px;
}

.btn-gerar-pdf {
  padding: 4px 8px;
  border: 1px solid #FA5B25;
  border-radius: 4px;
  background-color: transparent;
  margin-top: 10px;
  display: flex;
  align-items: end;
}
.btn-gerar-pdf a {
  color: #FA5B25;
  text-decoration: none;
}
.btn-gerar-pdf img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

small.minhaconta-vencimento .fontello-icon-info-circle {
  font-size: 14px;
}

@media (min-width: 880px) {
  #tenho-interesse-form .form-horizontal input, #tenho-interesse-form .form-horizontal textarea, #solicitar-orcamento-produto-form .form-horizontal input, #solicitar-orcamento-produto-form .form-horizontal textarea {
    max-width: 100%;
  }
}

#div_frm_comentario .form-horizontal {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#div_frm_comentario .has-error {
  margin-left: 0px;
}

.listagem_comentarios {
  margin-top: 25px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
.listagem_comentarios .small {
  margin-top: 0;
}
.listagem_comentarios .caixa_um_comentario {
  padding: 7px 9px;
  width: 100%;
  margin-right: 0;
}
@media (min-width: 760px) {
  .listagem_comentarios .caixa_um_comentario {
    width: 49%;
    margin-right: 2%;
  }
  .listagem_comentarios .caixa_um_comentario:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .listagem_comentarios .caixa_um_comentario {
    width: 32%;
    margin-right: 2%;
  }
  .listagem_comentarios .caixa_um_comentario:nth-child(2n) {
    margin-right: 2%;
  }
  .listagem_comentarios .caixa_um_comentario:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1600px) {
  .listagem_comentarios .caixa_um_comentario {
    width: 32%;
    margin-right: 1%;
  }
  .listagem_comentarios .caixa_um_comentario:nth-child(2n) {
    margin-right: 1%;
  }
  .listagem_comentarios .caixa_um_comentario:nth-child(3n) {
    margin-right: 0;
  }
}
.listagem_comentarios p {
  margin-bottom: 5px;
  font-size: 12px;
}
.listagem_comentarios .comentario-util {
  margin-top: 6px;
  padding-top: 6px;
  border-top: dotted 1px #cccccc;
}
.listagem_comentarios .bt_votar_positivo {
  margin-bottom: 15px;
  margin-right: 15px;
  display: inline;
}
.listagem_comentarios .bt_votar_negativo {
  display: inline;
}
@media (min-width: 480px) {
  .listagem_comentarios .comentario-util > .btn {
    width: auto;
  }
}
.listagem_comentarios .bt_votar_positivo {
  border: solid 1px #2C9A00;
  color: #2C9A00;
}
.listagem_comentarios .bt_votar_positivo:hover, .listagem_comentarios .bt_votar_positivo:focus {
  background-color: #eef8ea;
}
.listagem_comentarios .bt_votar_negativo {
  border: solid 1px #C00;
  color: #C00;
}
.listagem_comentarios .bt_votar_negativo:hover, .listagem_comentarios .bt_votar_negativo:focus {
  background-color: #f0e5e5;
}
.listagem_comentarios .td_avaliacao {
  font-size: 1.15em;
  margin-bottom: 5px;
}

.listagem_perguntaresposta {
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 12px;
}
.listagem_perguntaresposta .box_resposta {
  border-bottom: dotted 1px #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.listagem_perguntaresposta .box_resposta p.pergunta {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.listagem_perguntaresposta .box_resposta p.per_meta {
  font-size: 11px;
  color: #949494;
  margin-bottom: 4px;
}
.listagem_perguntaresposta .box_resposta div.resposta {
  margin-left: 25px;
}
.listagem_perguntaresposta .box_resposta .resposta_item {
  margin-top: 5px;
  border-top: 1px dotted #ccc;
  padding-top: 5px;
}
.listagem_perguntaresposta .box_resposta .sabe_resposta {
  float: right;
  margin-left: 25px;
  margin-bottom: 15px;
}
.listagem_perguntaresposta .box_resposta p {
  margin-bottom: 0;
}
.listagem_perguntaresposta .box_resposta .small {
  margin-top: 0;
}

.box-compra .custo {
  background-color: #fafafa;
}

.custo {
  font-size: 11px;
  border: dotted 1px #cccccc;
  padding: 5px 10px 3px 10px;
  background-color: #f2f2f2;
  margin-top: 5px;
}
.custo i.fontello-icon-attention-circle {
  color: #428bca;
}
.custo.custo-negativo {
  border-color: #C00;
  color: #a94442;
  background-color: #f2dede;
}
.custo.custo-vazio {
  border-color: #f0ad4e;
  color: #8a6d3b;
  background-color: #fcf8e3;
}

@media (min-width: 1024px) {
  .btn-comentario-enviar {
    float: right;
  }
}

.right-mobile {
  display: block;
}
@media (min-width: 1024px) {
  .right-mobile {
    float: right;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.left-mobile {
  display: block;
}
@media (min-width: 1024px) {
  .left-mobile {
    float: left;
  }
}

.categorias-index ul {
  border-bottom: dotted 1px #cccccc;
  padding-left: 0px;
  list-style: inside;
  margin-bottom: 30px;
}
.categorias-index li {
  padding: 5px 0;
  border-top: dotted 1px #cccccc;
}
.categorias-index h4 {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}
.categorias-index .panel-body {
  padding: 0;
}
.categorias-index .panel-body h4:first-child {
  margin-top: 0;
}
@media (min-width: 760px) {
  .categorias-index {
    max-width: 80%;
    margin: 0 auto;
  }
}
@media (min-width: 880px) {
  .categorias-index {
    max-width: 70%;
  }
}
@media (min-width: 1024px) {
  .categorias-index {
    max-width: 60%;
  }
}
@media (min-width: 1200px) {
  .categorias-index {
    max-width: 50%;
  }
}
@media (min-width: 1600px) {
  .categorias-index {
    max-width: 40%;
  }
}

#btn-feedback {
  display: none;
}
@media (min-width: 880px) {
  #btn-feedback {
    display: block;
    position: fixed;
    right: -5px;
    top: 400px;
    display: block;
    width: 36px;
    height: 100px;
    background-color: #FA5B25;
    color: #fff;
    font-weight: 700;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
}

#modalFeedback h3 {
  margin-bottom: 0;
}
#modalFeedback p {
  margin-bottom: 10px;
}
#modalFeedback hr {
  margin: 15px 0;
}
#modalFeedback #erro_feedback {
  color: red;
}
#modalFeedback ul.escala-recomendacao {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#modalFeedback ul.escala-recomendacao li {
  float: left;
  width: 10%;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  text-align: center;
  padding: 5px;
}
#modalFeedback ul.escala-recomendacao li:first-child {
  border-left: solid 1px #ccc;
}
#modalFeedback ul.escala-recomendacao li:hover {
  background-color: #f2f2f2;
}
#modalFeedback ul.escala-recomendacao li a {
  display: block;
}
#modalFeedback ul.escala-recomendacao li a:hover {
  text-decoration: none;
}
#modalFeedback ul.escala-recomendacao li a:focus {
  text-decoration: none;
}
#modalFeedback li.recomendacao-down {
  background-color: #e47911;
  color: #fff;
}
#modalFeedback li.recomendacao-down:hover {
  background-color: #d67110 !important;
}
#modalFeedback li.recomendacao-down a {
  color: #fff;
}
#modalFeedback #feedbackMensagem {
  width: 100% !important;
  padding: 10px;
}
#modalFeedback #feedbackResponse {
  display: none;
}

.nenhumProdutoEncontrado {
  display: none;
}

.adicionar-ao-carrinho .bigger {
  padding-top: 6px;
  margin-bottom: 15px;
}
.adicionar-ao-carrinho .btn-default {
  background-color: #fff;
}
.adicionar-ao-carrinho .fontello-icon-ok-circle-1 {
  color: #2c9a00;
}
@media (min-width: 640px) {
  .adicionar-ao-carrinho .bigger {
    margin-bottom: 0;
  }
}
@media (min-width: 880px) {
  .adicionar-ao-carrinho .bigger {
    padding-top: 6px;
  }
}
.adicionar-ao-carrinho .btn-carrinho {
  margin-top: 10px;
}
.adicionar-ao-carrinho .btn-carrinho i {
  margin-right: 3px;
  margin-left: 0;
  font-size: 10px;
}
.adicionar-ao-carrinho .grid-produto span.list-codigo {
  font-size: 10px;
}
.adicionar-ao-carrinho .grid-produto a.list-titulo {
  font-size: 15px;
  line-height: 20px;
}
.adicionar-ao-carrinho .grid-produto a.btn {
  padding: 4px 8px;
}
.adicionar-ao-carrinho .grid-produto a.adicionado-ver-carrinho {
  display: block;
  font-size: 12px;
  margin-top: 6px;
}

.btn-second {
  margin-top: 10px;
}
@media (min-width: 640px) {
  .btn-second {
    margin-top: 0;
  }
}

.seleciona-estado .estado {
  width: 65%;
}

.alert-produto-add-sucesso {
  padding: 10px;
}
@media (min-width: 640px) {
  .alert-produto-add-sucesso {
    padding: 15px;
  }
}

.table-minha-conta thead th {
  font-size: 14px;
}

.table-minha-conta tbody {
  font-size: 12px;
}

/* --------------------------------------------------------------- container categorialp --*/
.imagem_180 {
  text-align: center;
}
.imagem_180 img {
  display: block;
  width: 180px;
  margin: 0 auto;
}

.imagem_fabricante {
  text-align: center;
}
.imagem_fabricante img {
  display: block;
  width: 100px;
  margin: 0 auto;
}

a.link-destaque {
  font-size: 20px;
  font-weight: 700;
}

.leftbar-link {
  text-align: left;
  margin-top: 15px;
  margin-right: 15px;
  display: block;
}
.leftbar-link i {
  margin-left: 0;
}

.filtro-categoria-titulo-h2 {
  margin: 0;
}

.filtro-ativo-categoria, .filtro-ativo-fabricante {
  display: block;
  margin-right: 15px;
  margin-bottom: 5px;
  text-align: left;
  padding: 5px 0;
}

.filtro-ativo-categoria .fontello-icon-cancel-circle-2, .filtro-ativo-fabricante .fontello-icon-cancel-circle-2 {
  color: #666;
  margin-right: 5px;
  font-style: 10px;
}

.leftbar__subtitulo {
  background-color: #f2f2f2;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 5px;
  color: #666;
  padding: 5px 15px;
  font-size: 12px;
  margin-left: -15px;
}

.leftbar__subtitulo-destaque {
  color: #FA5B25;
  font-weight: bold;
  font-size: 14px;
  margin: 20px 0 7px 0;
}

.leftbar-desktop__margin {
  padding: 0 15px;
}

#content-full.categorias-footer {
  clear: both;
  padding-top: 0;
}
#content-full.categorias-footer h2:first-child {
  margin-top: 0;
}

.autocomplete-suggestions {
  border: 1px solid #999;
  background: #FFF;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 3px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

.btn-float-whatsapp {
  display: block;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 15px;
  right: 15px;
  margin: 0;
  padding: 0;
  z-index: 1000;
  background-color: #25d366;
  border-radius: 50px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
.btn-float-whatsapp span.bt-whatsapp-link {
  display: none;
}
.btn-float-whatsapp a.icon-whatapp {
  display: block;
  width: 44px;
  margin-top: 2px;
  margin-left: 4px;
}
.btn-float-whatsapp svg {
  border-radius: 50px;
}
@media (min-width: 640px) {
  .btn-float-whatsapp {
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
  }
  .btn-float-whatsapp a.icon-whatapp {
    width: 55px;
    margin-top: 2px;
    margin-left: 3px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 12px rgba(37, 211, 102, 0);
  }
}
#lgpd-banner {
  font-size: 12px;
  padding: 10px 0;
  background: #f2f2f2;
  overflow: hidden;
  color: #404040;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.1);
  border-top: solid 1px #cccccc;
  text-align: center;
}
#lgpd-banner button {
  margin-top: 10px;
}
@media (min-width: 640px) {
  #lgpd-banner button {
    margin-top: 0;
    margin-left: 10px;
  }
}

.cadastrese-box {
  background-color: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  margin: 20px 0;
}
@media (min-width: 1600px) {
  .cadastrese-box {
    width: 85%;
    margin: 20px auto;
  }
}

.cadastrese-title {
  background-color: #1b1811;
  padding: 8px 16px 8px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.cadastrese-content {
  padding: 16px 16px;
}
@media (min-width: 880px) {
  .cadastrese-content {
    display: flex;
    gap: 15px;
    align-items: center;
  }
}

.cadastrese-icon i.fontello-icon-mail-1:before {
  display: none;
}
@media (min-width: 640px) {
  .cadastrese-icon i.fontello-icon-mail-1:before {
    float: left;
    margin-right: 15px;
  }
}
@media (min-width: 880px) {
  .cadastrese-icon i.fontello-icon-mail-1:before {
    display: block;
    margin-right: 0;
    width: auto;
    line-height: auto;
    font-size: 32px;
  }
}

.cadastrese-form {
  overflow: hidden;
  margin-top: 15px;
}
.cadastrese-form .form-group-new {
  margin-bottom: 10px;
}
.cadastrese-form .form-group-new input {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 880px) {
  .cadastrese-form {
    margin-top: 0;
    display: flex;
    justify-content: end;
    flex: 1;
  }
  .cadastrese-form .form-group-new {
    margin-bottom: 0;
    flex-grow: 1;
    margin-right: 20px;
  }
}

.banner-zebra-essentials {
  background-image: url("../img/banner/zebra-essentials-banner-mobile.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 63px;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .banner-zebra-essentials {
    background-image: url("../img/banner/zebra-essentials-banner.jpg");
    height: 167px;
  }
}

.banner-footer-zebra.lazyloaded {
  background-image: url("../img/banner/banner-zebra-footer-mobile.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 450px;
  display: block;
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .banner-footer-zebra.lazyloaded {
    height: 650px;
  }
}
@media (min-width: 1024px) {
  .banner-footer-zebra.lazyloaded {
    background-image: url("../img/banner/banner-zebra-footer-desktop.jpg");
    height: 150px;
  }
}

.sobre-telefones {
  padding: 0;
  list-style: none;
}
@media (min-width: 480px) {
  .sobre-telefones {
    display: flex;
    flex-wrap: wrap;
  }
  .sobre-telefones li {
    flex-basis: 50%;
  }
}
@media (min-width: 1024px) {
  .sobre-telefones li {
    flex-basis: 33%;
  }
}
@media (min-width: 1200px) {
  .sobre-telefones li {
    flex-basis: 50%;
  }
}
@media (min-width: 1450px) {
  .sobre-telefones li {
    flex-basis: 33%;
  }
}

/*# sourceMappingURL=global-footer.css.map */
